import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import InfoIcon from '@mui/icons-material/Info';

import styles from "./styles";
import MultiSelect from "../multiSelect";
import { useDispatch, useSelector } from "react-redux";
import { getUserAuth, getUserCompanyId } from "../../../redux/slices/auth/authSlice";
import { categoryListAPI } from "../../../redux/slices/categories/categoriesThunk";
import { techStackListingAPI } from "../../../redux/slices/techStacks/techStacksThunk";
import { getAllCategories } from "../../../redux/slices/categories/categoriesSlice";
import { getAllTechStacks, getSelectedTechStack, resetSelectedCategory } from "../../../redux/slices/techStacks/techStacksSlice";
import { formatCategoryOptions, getTechStackIdAndName } from "../../../utils/sliceFilter";
import { boilerplateListingAPI } from "../../../redux/slices/boilerplate/boilerplateThunk";
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';

const BoilerAdvancedFilters = () => {
  const dispatch = useDispatch();
  const { userToken } = useSelector(getUserAuth);
  const company_id = useSelector(getUserCompanyId);
  const selectedTech = useSelector(getSelectedTechStack);

  const [techStack, setTechStack] = useState([]);
  const [category, setCategory] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");

  const { categories: _categories } = useSelector(getAllCategories);
  const { techStacks: _techStacks } = useSelector(getAllTechStacks);

  const validationSchema = z.object({
    name: z.any(),
    techStack: z.any(),
    category: z.any(),
  });

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: "",
      techStack: [],
      category: [],
    }
  });

  useEffect(() => {
    if (selectedTech) {
      reset({
        techStack: selectedTech === "allstack" ? [] : [selectedTech],
        category: [],
      });
       setCategory([]);
    } else {
      reset({
        techStack: [],
        category: [],
      });
       setCategory([]);
    }
 
    setTechStack(selectedTech === "allstack" ? [] : [selectedTech]);

    let payload = {};
    payload.userToken = userToken;
    payload.companyId = company_id;

    dispatch(categoryListAPI(payload));
    dispatch(techStackListingAPI(payload));
    // Check if the category reset is needed
    if (selectedTech) {
        dispatch(resetSelectedCategory());
    }
  }, [company_id, userToken, dispatch, selectedTech, reset]);

  const handleFormReset = () => {
    reset({
      name: "",
      techStack: [],
      category: [],
    });
    setTechStack([]);
    setCategory([]);
  };

const handleRefresh = () => {
  handleFormReset(); // This clears the form inputs
  dispatch(boilerplateListingAPI({
    userToken, 
    companyId: company_id,
    search: "", 
    category: "", 
    techStack: [] 
  }));
};



  
const onSubmit = (data, e) => {
  e.preventDefault();

  // Convert techStack and category to arrays if not already
  const techStackArray = Array.isArray(data.techStack) ? data.techStack : [data.techStack];
  const categoryArray = Array.isArray(data.category) ? data.category : [data.category];

  // Check if "All Categories" is selected
  const isAllCategoriesSelected = categoryArray.includes("All Categories");

  // Construct the search query for tech stack
  const search = techStackArray.includes("All Stack") ? "allstack" : techStackArray.join(",");

  // Prepare the payload for the API call
  const payload = {
    ...data,
    userToken,
    companyId: company_id,
    search, 
    boilerplate_name: data.name, 
    // If "All Categories" is selected, set category to an empty string to ignore filtering by category
    category: isAllCategoriesSelected ? "" : categoryArray.join(","),
  };

  // Dispatch the API call with the payload
  dispatch(boilerplateListingAPI(payload));

  // Update filter status message
  let statusMessage = "Currently filtering by ";
  if (!data.name && techStackArray.length === 0 && (isAllCategoriesSelected || categoryArray.length === 0)) {
    statusMessage += "All Techstacks"; // No filters applied or only "All Categories" selected
  } else {
    if (techStackArray.length > 0) {
      statusMessage += `Techstack: ${techStackArray.join(", ")}`;
    }
    if (!isAllCategoriesSelected && categoryArray.length > 0) {
      statusMessage += (techStackArray.length > 0 ? " and " : "") + `Category: ${categoryArray.join(", ")}`;
    }
  }

  setFilterStatus(statusMessage || "No filters applied.");

  // Reset the form but retain selected filters
  reset({
    name: data.name,
    techStack: data.techStack,
    category: data.category,
  });
};



  return (
    <form autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
      <Stack mb={2} direction="row" sx={styles.afStack}>
        <Box>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Search Boilerplate
            <Tooltip title="Enter the name of the boilerplate you’re looking for." arrow>
        <InfoIcon sx={{ fontSize: '14px', ml: 1 ,color: '#1976d2'}} />
      </Tooltip>
          </Typography>
          <TextField
            placeholder="Search Boilerplate"
            id="name"
            type="text"
            {...register("name")}
            helperText={errors.name?.message}
            error={Boolean(errors.name)}
            sx={styles.ctgTextField}
          />
        </Box>

        <Box>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Search by Project Category
            <Tooltip title="Select a category to filter the results." arrow>
              <InfoIcon sx={{ fontSize: '14px', ml: 1,color: '#1976d2' }} />
            </Tooltip>
          </Typography>
          <MultiSelect
            placeholder={"Search by Project Category"}
            id={"isActive"}
            selectedData={category}
            setSelectedData={setCategory}
            apiData={formatCategoryOptions(_categories)}
            register={register("category")}
            error={errors.category}
            multiline={false}
            style={{ width: "280px", background: "#ffffff" }}
          />
        </Box>

        <Box>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Search by Techstack
            <Tooltip title="Select tech stacks to refine your search." arrow>
              <InfoIcon sx={{ fontSize: '14px', ml: 1 ,color: '#1976d2'}} />
            </Tooltip>
          </Typography>
          <MultiSelect
            placeholder={"Search by Techstack"}
            id={"tech-stack"}
            selectedData={techStack}
            setSelectedData={setTechStack}
            apiData={getTechStackIdAndName(_techStacks)} 
            register={register("techStack")}
            error={errors.techStack}
            multiline={false}
            style={{ width: "280px", background: "#ffffff" }}
          />
        </Box>

        <Box sx={styles.closeBtn}>
          <Tooltip title="Clear filter">
            <IconButton
              aria-label="clear-filter"
              size="medium"
              onClick={handleFormReset}
              sx={{ color: "#ff1744" }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </Tooltip>

        {/* Refresh Icon */}
          <Tooltip title="Refresh filter">
            <IconButton
              aria-label="refresh-filter"
              size="medium"
              onClick={handleRefresh} 
              sx={{ color: "#1976d2" }} 
            >
              <RefreshIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>

        <Box>
          <Button variant="contained" type={"submit"} sx={styles.ctgBtn} fullWidth={true}>
            Search
          </Button>
        </Box>
      </Stack>
{/* <Box>
  {filterStatus ? (
    <Badge
      sx={{
        mt: 2,
        padding: "8px 16px",
        fontSize: "14px",
        fontWeight: "bold",
        backgroundColor: "#4caf50", 
        color: "#ffffff",  
        borderRadius: "20px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",  
        display: "inline-block", 
        textAlign: "center",
      }}
    >
      {filterStatus}
    </Badge>
  ) : (
    <Typography
      sx={{
        mt: 0.2,
        padding: "8px 16px",
        fontSize: "14px",
        fontWeight: "bold",
        backgroundColor: "#e0e0e0", 
        color: "#424242", 
        borderRadius: "20px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        display: "inline-block",
        textAlign: "center",
      }}
    >
      Showing all available boilerplates. Use filters to refine your search.
    </Typography>
  )}
</Box> */}



    </form>
  );
};

export default BoilerAdvancedFilters;
