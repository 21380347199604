import React from 'react';

import { Box } from '@mui/material';

import styles from './styles';
import img1 from "../../assets/login/top_right_corner.svg";
import img2 from "../../assets/login/bottom_right_corner.svg";

const RightSide = () => {
  return (
    <Box
      flex={3}
      sx={styles.rightBox}
    >
      <Box mt={4} mr={4}>
        <img src={img1} alt="upcorner"/>
      </Box>
      <Box mb={4} mr={4}>
        <img src={img2} alt="downcorner"/>
      </Box>
    </Box>
  )
}

export default RightSide;