import React from "react";

import { Box, Container, Typography } from "@mui/material";

import styles from "./styles";
import logo from "./../../assets/login/logo_white.svg";

const LeftSide = () => {
  const mainText = "Our boilerplates ensure standardized, reusable components across all tech stacks, streamlining development and enhancing code quality for consistent and efficient software projects."

  return (
    <Box
      flex={5}
      sx={styles.leftBox}
    >
      <Container
        maxWidth={"sm"}
        sx={styles.wlcContainer}
      >
        <Box sx={styles.wlcBox}>
          <Box sx={styles.mainLogo}>
            <img src={logo} alt="purelogics" />
          </Box>
          <Box>
            <Typography component="span" sx={styles.subTitle}>
              Welcome to
            </Typography>
            <Typography component="h1" sx={styles.mainTitle}>
              Boilerplate
            </Typography>
            <Typography component="p" sx={styles.mainText}>
              {mainText}
            </Typography>
          </Box>
          <Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default LeftSide;