import { Helmet } from "react-helmet";

const BrowserTabTitle = ({ title }) => {
  return (
    <Helmet>
      <title>{title} | Codecrate</title>
    </Helmet>
  )
}

export default BrowserTabTitle;