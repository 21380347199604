import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/axiosInstance";
import { getNotificationCount } from "../../../utils/getNotificationCount";

// Thunks

// notification listing API
export const listNotificationAPI = createAsyncThunk(
  "notifications/listNotificationAPI",
  async (payload, { rejectWithValue }) => {
    const { userToken } = payload;
    try {
      const headers = { Authorization: `Bearer ${userToken}` };
      const { data } = await api.get(`/api/notification`, { headers });
      return data;
    } catch (error) {
      if (error.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong");
      }
    }
  }
);

// udpate notification api to update is_read status to True
export const updateNotificationAPI = createAsyncThunk(
  "notifications/updateNotificationAPI",
  async (payload, { rejectWithValue }) => {
    const { userToken, id, isRead } = payload;

    const body = { is_read: isRead };

    try {
      const headers = { Authorization: `Bearer ${userToken}` };
      const response = await api.put(`/api/notification/${id}/`, body, {
        headers,
      });
      return { id, isRead: response.data.data.is_read }; 
    } catch (error) {
      if (error.message) {
        // return rejectWithValue(error.message);
      }
      return rejectWithValue("Something went wrong");
    }
  }
);

// Thunk handlers

export const handlers = {
  listNotificationAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error = null;
    },
    success: (state, action) => {
      state.isLoading = false;

      // Extract the data array from the action.payload object
      const notifications = Array.isArray(action.payload?.data)
        ? action.payload.data
        : [];

      state.notifications = notifications;
      state.notificationCount = getNotificationCount(notifications); 
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },

  updateNotificationAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error = null;
      return state;
    },
    success: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;

      if (typeof action.payload.isRead === "undefined") {
        console.error("isRead is undefined, skipping update");
        return;
      }

      const index = state.notifications.findIndex(
        (notification) => notification.id === action.payload.id
      );

      if (index !== -1) {
        state.notifications[index].is_read = action.payload.isRead;
      }

      state.notificationCount = getNotificationCount(state.notifications);
      return state;
    },

    

    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },
};
