import React from "react";
import { Box, Stack } from "@mui/material";

import "./spinner.css";

const LoadingSpinner = ({ message }) => {
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      gap={2}
    >
      <Box className="spinner-container">
        <Box className="loading-spinner">
        </Box>
      </Box>
      <Box>
        <p>{message}</p>
      </Box>
    </Stack>
  );
}

export default LoadingSpinner;