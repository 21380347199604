import React, { useEffect, useState } from "react";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import styles from "./styles";
import BoilerRating from "../boilerRating";
import DevName from "./DevName";
import AmplifySpinner from "../../LoadingSpinner/AmplifySpinner";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";
import { dateFormatter } from "../../../utils/dateFormat";
import CtgStatus from "./CtgStatus";
import { descriptionParser } from "../../../utils/descriptionParser";
import { useSelector } from "react-redux";
import { getUserAuth } from "../../../redux/slices/auth/authSlice";
import { checkIsAdmin } from "../../../utils/checkIsAdmin";


const BoilerTable = (props) => {
  const { columns, rows, isLoading, previousPageUrl, nextPageUrl, handleBackendPagination, totalCount, search } = props;

  const navigate = useNavigate();

  const { isAdmin, userProfile } = useSelector(getUserAuth);
  const checkAdmin = checkIsAdmin(isAdmin, userProfile?.userType);

  const filterColumns = columns.filter(column => !column.isAdminOnly || isAdmin);

  const [rowPage, setRowPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    // Reset pagination when the search criteria (filter) changes
    setRowPage(0);
  }, [search]);

  const handleUpdate = (id) => {
    navigate(`/dashboard/update-boilerplate/${id}`);
  };

  const handleCtgUpdate = (id) => {
    navigate(`/dashboard/boilerplate-category/update/${id}`);
  };

  const handleTechUpdate = (id) => {
    navigate(`/dashboard/boilerplate-techstack/update/${id}`);
  };

  const handleView = (id) => {
    navigate(`/dashboard/view-boilerplate/${id}`);
  };

  const handlePageChange = (event, newPage) => {
    setRowPage(newPage);
    if (newPage > rowPage) {
      handleBackendPagination(nextPageUrl);
    } else {
      handleBackendPagination(previousPageUrl);
    }
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setRowPage(0);
  };

  const renderCellContent = (columnId, value) => {
    if (value === null || value === undefined || value === "") {
      return (
        <Typography sx={styles.tableNA}>N/A</Typography>
      );
    }

    switch (columnId) {
      case 'last_download':
        return dateFormatter(value);
      case 'developer_name':
        return <DevName name={value} />;
      case 'rating':
        return <BoilerRating value={value} />;
      case 'description':
        return descriptionParser(value);
      case 'is_active':
        return <CtgStatus value={value} />;
      case 'icon_file':
        return <img src={value} alt="icon" width={30} />;
      case 'boilerplate_id':
        return (
          <>
            {checkAdmin && (
              <IconButton aria-label="edit" size="small" onClick={() => handleUpdate(value)}>
                <EditIcon fontSize={"small"} />
              </IconButton>
            )}
            <IconButton aria-label="view" size="small" onClick={() => handleView(value)}>
              <VisibilityIcon fontSize={"small"} />
            </IconButton>
          </>
        );
      case 'category_id':
        return (
          <>
            <IconButton aria-label="edit" size="small" onClick={() => handleCtgUpdate(value)}>
              <EditIcon fontSize={"small"} />
            </IconButton>
          </>
        );
      case 'tech_id':
        return (
          <>
            <IconButton aria-label="edit" size="small" onClick={() => handleTechUpdate(value)}>
              <EditIcon fontSize={"small"} />
            </IconButton>
          </>
        );
      default:
        return value;
    }
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="boiler-table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={styles.tableHeadCell}>#</TableCell>
              {filterColumns?.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sx={styles.tableHeadCell}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={filterColumns.length + 1}>
                  <AmplifySpinner />
                </TableCell>
              </TableRow>
            ) : rows?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={filterColumns?.length + 1}
                  align="center"
                  sx={styles.tableEmptyText}
                >
                  Nothing is created yet
                </TableCell>
              </TableRow>
            ) : (
              rows?.map((row, index) => (
                <TableRow key={index}>
                  {/* Add Row Number */}
                  <TableCell align="center" sx={styles.tableBodyCell}>
                    {rowPage * rowsPerPage + index + 1}
                  </TableCell>
                  {filterColumns.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      sx={styles.tableBodyCell}
                    >
                      {renderCellContent(column.id, row[column.id])}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={styles.tablePagination}
        rowsPerPageOptions={[10]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={rowPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  );
}

export default BoilerTable;
