import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    background: {
      default: "#ffffff"
    },
    primary: {
      main: "#4ABA6A",
    },
    secondary: {
      main: "#00235A",
    },
  },
  typography: {
    fontFamily: "'Sen', sans-serif",
    button: {
      textTransform: "none",
    },

    h2: {
      fontWeight: 600,
      fontSize: "28px",
      lineHeight: "33.6px",
      color: "#495057",
      // [breakpoints.up("md")]: {
      //   fontSize: "64px",
      //   lineHeight: "78px",
      // },
    },

    body1: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "21px",
      color: "#212529",
      // [breakpoints.up("md")]: {
      //   fontSize: "24px",
      //   lineHeight: "29px",
      //   fontWeight: 500,
      // },
    },

    body2: {
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "15.6px",
      color: "#495057",
      // [breakpoints.up("md")]: {
      //   fontSize: "24px",
      //   lineHeight: "29px",
      //   fontWeight: 500,
      // },
    },
  },
  components: {
    // Outlined input textfield
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 400,
          backgroundColor: "#F3F3F9",
          // backgroundColor: "#e8fff4",
          '& .MuiOutlinedInput-notchedOutline': {
            border: "none",
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #4ABA6A',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #4ABA6A',
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #d32f2f',
          },
          '& .MuiInputBase-input::placeholder': {
            fontSize: '12px',
          },
          '& .MuiInputBase-input': {
            padding: '10px',
          },
        },
      },
    },

    // Contained Button 
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "10px",
          color: '#fff',
          backgroundColor: '#4ABA6A',
          fontWeight: 700,
          fontSize: "15px",
          lineHeight: "22.5px",
          borderRadius: 0,
        },
      },
    },

  },
});

export default theme;

