import { Backdrop } from "@mui/material";
import React from "react";
import LoadingSpinner from "./LoadingSpinner";

const BackdropLoading = ({ message, isLoading }) => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={isLoading}
    >
      <LoadingSpinner message={message} />
    </Backdrop>
  )
}

export default BackdropLoading;