export const getURLParams = (url) => {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  const paramsObj = {};

  params.forEach((value, key) => {
    paramsObj[key] = value;
  });

  return paramsObj;
};