const styles = {
  techPaper: (theme) => ({
    maxWidth: "934.16px",
    // maxWidth: "1100px",
    border: "1px solid #E9EBEC",
    borderRadius: "4px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "622.77px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "467.08px",
    },
    [theme.breakpoints.down(500)]: {
      maxWidth: "350px",
    },
  }),

  techBtnBox: {
    marginBottom: "10px",
  },

  container: {
    height: "100vh", // Full height of the viewport
    display: "flex",
    flexDirection: "column",
  },

  content: {
    flex: 1,
    overflow: "hidden", // Prevents scrolling on the whole page
  },

  tableContainer: {
    overflowY: "auto", // Allows scrolling within the table area if content overflows
  },
};

export default styles;
