import React, { useEffect } from "react";
import BrowserTabTitle from "../../../components/BrowserTabTitle";
import { Box, Container, Paper } from "@mui/material";
import PageTitle from "../../../components/Dashboard/includes/pagetitle";
import styles from "./styles";
import BoilerTable from "../../../components/Dashboard/table";
import { useDispatch, useSelector } from "react-redux";
import { boilerplateListingAPI } from "../../../redux/slices/boilerplate/boilerplateThunk";
import { getUserAuth, getUserCompanyId } from "../../../redux/slices/auth/authSlice";
import { getAllBoilerplates } from "../../../redux/slices/boilerplate/boilerplateSlice";
import { getURLParams } from "../../../utils/getUrlParams";
import { useParams } from "react-router-dom";
import { ErrorAlert } from "../../../components/Alerts/Alerts";
import BoilerAdvancedFilters from "../../../components/Dashboard/boilerAdvancedFilters/BoilerAdvancedFilters";
import FilterModal from "./modals/FilterModal/FilterModal";

const BoilerplateListing = () => {
  const dispatch = useDispatch();
  const { search } = useParams();
  const { userToken ,isAdmin} = useSelector(getUserAuth);
  const company_id = useSelector(getUserCompanyId);
  const { isLoading, error, boilerplates, previousPageUrl, nextPageUrl, totalCount } = useSelector(getAllBoilerplates);
  
  useEffect(() => {
    const payload = {
      userToken,
      search,
      companyId: company_id,
    };
    dispatch(boilerplateListingAPI(payload));
  }, [dispatch, search, userToken, company_id]);

  const columns = [
    { id: 'boilerplate_name', label: 'Boilerplate', minWidth: 170 },
    // { id: 'last_download', label: 'Last Download', minWidth: 166.83, align: "center" },
    { id: 'category_names', label: 'Project Category', minWidth: 150, align: "center" },
    { id: 'tech_names', label: 'Tech Stacks', minWidth: 120 },
    { id: 'developers', label: 'Developer Name', minWidth: 120.48 },
    {id: 'updated_at', label: 'Updated At', minWidth: 112.09, align: "center"},
    // { id: 'is_active', label: 'Active', minWidth: 100, align: "center", isAdminOnly: true },
    { id: 'rating', label: 'Feedback Rating', minWidth: 112.09, align: "center" },
    { 
      id: 'boilerplate_id', 
      label: isAdmin ? 'Actions' : 'Action',  
      minWidth: 112.09, 
      align: "center" 
    },
  ];

  const handleBackendPagination = (link) => {
    if (!link) {
      alert("Pagination ended, no further links available");
      return;
    }
    const params = getURLParams(link);
    const payload = {
      ...params,
      userToken,
      companyId: company_id,
    };
    dispatch(boilerplateListingAPI(payload));
  };

  return (
    <>
      <BrowserTabTitle title="Boilerplate Listing" />
      {error && <ErrorAlert message={error} />}
      <Container maxWidth="">
        <PageTitle title="Boilerplate Listing" />

        {/* Boilerplate Advanced Filters */}
        <Box sx={{ mb: 2 }}>
          <BoilerAdvancedFilters />
        </Box>

        {/* Boilerplate Listing Table */}
        <Paper
          elevation={0}
          // sx={styles.blPaper}
        >
          <BoilerTable
            columns={columns}
            rows={boilerplates}
            isLoading={isLoading}
            previousPageUrl={previousPageUrl}
            nextPageUrl={nextPageUrl}
            totalCount={totalCount}
            search={search}
            handleBackendPagination={handleBackendPagination}
            nextIconButtonProps={{ disabled: !nextPageUrl }}
            backIconButtonProps={{ disabled: !previousPageUrl }}
          />
        </Paper>
      </Container>

      {/* Filter Modal */}
      <FilterModal />
    </>
  );
};

export default BoilerplateListing;
