import { createSlice } from '@reduxjs/toolkit'
import {
  categoryDetailAPI,
  categoryListAPI,
  categoryUpdateAPI,
  categoryListingPaginatedAPI,
  createCategoryAPI,
  handlers,
} from "./categoriesThunk";

const initialState = {
  isLoading: false,
  error: null,
  message: null,
  isSuccess: false,
  categories: [],
  categoryDetail: [],
  previousPageUrl: null,
  nextPageUrl: null,
  totalCount: 0,
};


export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(categoryListAPI.pending, handlers.categoryListAPI.pending);
    builder.addCase(categoryListAPI.fulfilled, handlers.categoryListAPI.success);
    builder.addCase(categoryListAPI.rejected, handlers.categoryListAPI.rejected);

    builder.addCase(
      categoryListingPaginatedAPI.pending,
      handlers.categoryListingPaginatedAPI.pending
    );
    builder.addCase(
      categoryListingPaginatedAPI.fulfilled,
      handlers.categoryListingPaginatedAPI.success
    );
    builder.addCase(
      categoryListingPaginatedAPI.rejected,
      handlers.categoryListingPaginatedAPI.rejected
    );


    builder.addCase(createCategoryAPI.pending, handlers.createCategoryAPI.pending);
    builder.addCase(createCategoryAPI.fulfilled, handlers.createCategoryAPI.success);
    builder.addCase(createCategoryAPI.rejected, handlers.createCategoryAPI.rejected);

    builder.addCase(categoryDetailAPI.pending, handlers.categoryDetailAPI.pending);
    builder.addCase(categoryDetailAPI.fulfilled, handlers.categoryDetailAPI.success);
    builder.addCase(categoryDetailAPI.rejected, handlers.categoryDetailAPI.rejected);

    builder.addCase(categoryUpdateAPI.pending, handlers.categoryUpdateAPI.pending);
    builder.addCase(categoryUpdateAPI.fulfilled, handlers.categoryUpdateAPI.success);
    builder.addCase(categoryUpdateAPI.rejected, handlers.categoryUpdateAPI.rejected);
  }
})

export const getAllCategories = (state) => state.categories;


export default categoriesSlice.reducer;