import bgImg from "../../assets/login/background.svg";

const styles = {
  // LeftSide.jsx
  leftBox: (theme) => ({
    display: "flex",
    backgroundImage: `url(${bgImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down(885)]: {
      display: "none",
    },
  }),
  wlcContainer: (theme) => ({
    display: "flex",
    maxWidth: "666px !important",
  }),
  wlcBox: (theme) => ({
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    color: "#ffffff",
  }),
  mainLogo: (theme) => ({
    marginTop: "35px",
  }),
  subTitle: (theme) => ({
    fontWeight: 700,
    fontSize: "34px",
    lineHeight: "46.25px",
    color: "#ffffff",
  }),
  mainTitle: (theme) => ({
    fontWeight: 700,
    fontSize: "64px",
    lineHeight: "87.05px",
    color: "#ffffff",
  }),
  mainText: (theme) => ({
    maxWidth: "583px",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "32.4px",
    color: "#ffffff",
  }),

  // MiddleSide.jsx
  midContainer: (theme) => ({
    display: "flex",
    maxWidth: "500px",
    marginLeft: "45px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "4px",
    },
  }),
  midBox: (theme) => ({
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  }),
  techImgBox: (theme) => ({
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  }),
  techIcons: (theme) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "15px", // Adjust gap as needed
  }),
  techImg: (theme) => ({
    [theme.breakpoints.down("sm")]: {
      width: "280px",
    },
  }),

  // GoogleLogin.jsx
  glmt: (theme) => ({
    fontWeight: 700,
    fontSize: "34px",
    lineHeight: "55.09px",
    color: `${theme.palette.secondary.main}`,
  }),
  glp: (theme) => ({
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "32.4px",
    color: `${theme.palette.secondary.main}`,
  }),
  googleButton: (theme) => ({
    backgroundColor: "#EDEDED",
    border: "1px solid #E2E2E2",
    borderRadius: "10px",
    padding: "5px 15px",
    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  }),
  gLogo: (theme) => ({
    margin: "5px 12px 0 0",
  }),

  // RightSide.jsx
  rightBox: (theme) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    [theme.breakpoints.down(1287)]: {
      display: "none",
    },
  }),
};

export default styles;
