import React, { useEffect } from "react";
import BrowserTabTitle from "../../../components/BrowserTabTitle";
import { Box, Container } from "@mui/material";
import PageTitle from "../../../components/Dashboard/includes/pagetitle/PageTitile";
import BoilerView from "../../../components/Dashboard/boilerView";
import { useDispatch, useSelector } from "react-redux";
import { boilerplateDetailAPI } from "../../../redux/slices/boilerplate/boilerplateThunk";
import { getUserAuth, getUserCompanyId } from "../../../redux/slices/auth/authSlice";
import { useParams } from "react-router-dom";
import { getAllBoilerplates, getBoilerplateDetail } from "../../../redux/slices/boilerplate/boilerplateSlice";
import Feedbacks from "../../../components/Dashboard/feedbacks";
import AmplifySpinner from "../../../components/LoadingSpinner/AmplifySpinner";
import { ErrorAlert, SuccessAlert } from "../../../components/Alerts/Alerts";
import { getFeedbacksAPI } from "../../../redux/slices/feedbacks/feedbacksThunk";
import { getAllFeedbacks } from "../../../redux/slices/feedbacks/feedbacksSlice";
import DownloadReasonModal from "./modals/DownloadReasonModal";
import { getDownloadReasonAPI } from "../../../redux/slices/downloadReason/downloadReasonThunk";
import { getDownloadReasons, setIsSuccess } from "../../../redux/slices/downloadReason/downloadReasonSlice";

const ViewBoilerplate = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { userToken } = useSelector(getUserAuth);

  const { isLoading: isBoilerplateLoading, error: boilerplateError } = useSelector(getAllBoilerplates);
  const { error: feedbackError, feedbacks: feedData } = useSelector(getAllFeedbacks);
  // const { isSuccess: isDRSuccess, message: DRmsg } = useSelector(getDownloadReasons);
  const boilerDetail = useSelector(getBoilerplateDetail);
  const company_id = useSelector(getUserCompanyId);
  useEffect(() => {
    let payload = {};
    payload.userToken = userToken;
    payload.id = id;
    payload.companyId = company_id;
    if (id) {
      dispatch(boilerplateDetailAPI(payload));
      dispatch(getFeedbacksAPI(payload));
      dispatch(getDownloadReasonAPI(payload));
    }
  }, [id, userToken, dispatch,company_id]);

  const isLoading = isBoilerplateLoading;
  // const success = isDRSuccess;
  const error = boilerplateError || feedbackError;

  return (
    <>
      <BrowserTabTitle title={"View Boilerplate"} />
      {/* {success && <SuccessAlert message={DRmsg} />} */}
      {error && <ErrorAlert message={error} />}
      {isLoading ? (
        <Container maxWidth="">
          <Box pt={2}>
            <AmplifySpinner />
          </Box>
        </Container>
      ) : (
        <>
          <Container maxWidth="">
            <PageTitle
              title={"View Boilerplate"}
              goBackPath={"/dashboard/boilerplate-listing/allstack"}
            />
            <BoilerView boilerDetail={boilerDetail} />
            <Feedbacks feedData={feedData} />
          </Container>
          <DownloadReasonModal />
        </>
      )}
    </>
  )
}

export default ViewBoilerplate;