const styles = {
  bvPaper: (theme) => ({
    maxWidth: "1026px",
    border: "1px solid #E9EBEC",
    borderRadius: "4px",
    padding: "30px 30px",
  }),
  bvStack: (theme) => ({
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    },
  }),
  bvpdf: (theme) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  }),
  bvBD: (theme) => ({
    maxWidth: "365px",
    maxHeight: "110px",
    overflowY: "auto",
  }),
  bvCB: (theme) => ({
    maxWidth: "365px",
  }),
  bvChip: (theme) => ({
    margin: "0 8px 5px 0",
  }),
}

export default styles;