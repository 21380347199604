import React from 'react';
import {
  Container,
  IconContainer,
  Heading,
  Description,
  // StyledButton,
  ImageWrapper,
} from './style'; 
import comingSoonImage from '../../../assets/login/Under construction.svg';


const ComingSoon = () => {
  return (
    <Container>
      <ImageWrapper src={comingSoonImage} alt="Coming Soon" draggable="false" />
      <IconContainer>
      </IconContainer>
      <Heading variant="h4" gutterBottom>
        Exciting Features Coming Soon!
      </Heading>
      <Description variant="body1" gutterBottom>
        Stay tuned for new tools and enhancements designed to elevate your experience. We're working hard to bring you more power, flexibility, and efficiency. Check back soon!
      </Description>
      {/* <StyledButton variant="contained" color="success" href="/">
        Back to Home
      </StyledButton> */}
    </Container>
  );
};

export default ComingSoon;
