import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  auditors: [],
};


export const auditorsSlice = createSlice({
  name: 'auditors',
  initialState,
  reducers: {
    addAuditor: (state, action) => {
      state.auditors.push(action.payload);
    },
    setAuditors: (state, action) => {
      state.auditors = action.payload;
    },
    removeAuditor: (state, action) => {
      state.auditors = state.auditors.filter((auditor, index) => index !== action.payload);
    },
  },

})


export const { addAuditor, setAuditors, removeAuditor } = auditorsSlice.actions;
export const getAuditors = (state) => state.auditors.auditors;



export default auditorsSlice.reducer;