import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/axiosInstance";

// Thunks 

export const googleLoginAPI = createAsyncThunk(
  'auth/googleLogin',
  async (googleResponse, { rejectWithValue }) => {
    try {
      const body = {
        token: googleResponse.access_token,
        full_name: googleResponse.data.name,
        email: googleResponse.data.email,
        avatar: googleResponse.data.picture,
      }

      const { data } = await api.post("/api/google-login", body);
      return data;
    }
    catch (error) {
      if (error.response && error.response.data.company[0]) {
        return rejectWithValue(error.response.data.company[0]);
      }
      else {
        return rejectWithValue(error.message);
      }
    }
  });


// Thunk handlers

export const handlers = {
  googleLoginAPI: {
    pending: (state) => {
      state.isLoading = true
      return state;
    },
    success: (state, action) => {
      const { token, is_staff, user_info } = action?.payload;
      state.isLoading = false;
      state.userToken = token;
      state.isLoggedIn = true;
      state.userProfile.userType = user_info?.user_type;
      state.userProfile.userCompany = user_info?.user_company;
      state.userProfile.name = user_info?.name;
      state.userProfile.email = user_info?.email;
      state.userProfile.picture = user_info?.avatar;
      state.isAdmin = is_staff;
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload
      return state;
    }
  },
};