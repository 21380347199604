import React, { useState } from "react";

import { Box, Stack } from "@mui/material";
import Sidebar from "../components/Dashboard/includes/sidebar";
import Navbar from "../components/Dashboard/includes/navbar/Navbar";

const DashboardLayout = ({ children }) => {
  const [drawOpen, setDrawOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setDrawOpen(newOpen);
  };

  return (
    <Stack direction="row">
      {/* Leftside */}
      <Sidebar
        // tabs={tabs}
        drawOpen={drawOpen}
        etDrawOpen={setDrawOpen}
        toggleDrawer={toggleDrawer}
      />

      {/* Rightside */}
      <Box flex={11} className="dashboard-body">
        {/* Navbar */}
        <Navbar
          drawOpen={drawOpen}
          setDrawOpen={setDrawOpen}
          toggleDrawer={toggleDrawer}
        />

        {/* Body */}
        {children}

        {/* Footer */}
        <></>
      </Box>
    </Stack>
  );
}

export default DashboardLayout;
