const styles = {
  ctgPaper: (theme) => ({
    maxWidth: "934.16px",
    // maxWidth: "1100px",
    border: "1px solid #E9EBEC",
    borderRadius: "4px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "622.77px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "467.08px",
    },
    [theme.breakpoints.down(500)]: {
      maxWidth: "350px",
    },
  }),

  ctgBtnBox: (theme) => ({
    marginBottom: "10px"
  }),
}

export default styles;