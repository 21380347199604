export const filterGetBoilerData = (data) => {
  // Extract the necessary fields from code_audit_status
  const code_audit_status = data?.code_audit_status?.status;

  // Extract the necessary fields from tech
  const tech = data?.tech?.map(item => item.tech_name);

  // Extract the necessary fields from category
  const category = data?.category?.map(item => item.category_name);

  // Extract the necessary fields from keywords
  const keywords = data?.keywords?.map(item => item.name);

  // Map the developers fields to required format
  const developers = data?.developers?.map(dev => ({
    name: dev?.developer_name,
    email: dev?.developer_email
  }));

  // Map the auditors fields to required format
  const auditors = data?.auditors?.map(aud => ({
    name: aud?.auditor_name,
    email: aud?.auditor_email
  }));

  // Construct the cleaned data object
  const filterData = {
    id: data?.id,
    boilerplate_name: data?.boilerplate_name,
    description: data?.description,
    bitbucket_url: data?.bitbucket_url,
    sample_work_url: data?.sample_work_url,
    code_quality: data?.code_quality,
    code_audit_report: data?.code_audit_report,
    comments: data?.comments,
    code_zip_file: data?.code_zip_file,
    is_active: data?.is_active,
    created_at: data?.created_at,
    company: data?.company,
    code_audit_status: code_audit_status,
    tech: tech,
    category: category,
    keywords: keywords,
    developers: developers,
    auditors: auditors
  };

  return filterData;
}