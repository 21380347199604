import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/axiosInstance";
import { toast } from "react-toastify";

// Thunks

// category listing api (return all created categories)
export const categoryListAPI = createAsyncThunk(
  "categories/categoryListAPI",
  async (payload, { rejectWithValue }) => {
    const { userToken, companyId } = payload;
    try {
      const headers = { Authorization: `Bearer ${userToken}` };
      const { data } = await api.get(
        `/api/category-list?company=${companyId}`,
        { headers }
      );
      return data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong");
      }
    }
  }
);

// Thunk to get all Paginated categories
export const categoryListingPaginatedAPI = createAsyncThunk(
  "category/categoryListingPaginatedAPI",
  async (payload, { rejectWithValue }) => {
    const { userToken, companyId, page: pageParam } = payload;

    let page = pageParam ?? "";

    try {
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };

      const response = await api.get(
        `/api/category-list-paginated?page=${page}&company=${companyId}`,
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching category listing:", error);

      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.message) {
        return rejectWithValue(error.message);
      }

      return rejectWithValue("Something went wrong");
    }
  }
);

// create category api for creating categories
export const createCategoryAPI = createAsyncThunk(
  "categories/createCategoryAPI",
  async (payload, { rejectWithValue }) => {
    const { userToken, name, description, navigate, companyId } = payload;

    const body = {
      category_name: name,
      description: description,
    };

    try {
      const headers = { Authorization: `Bearer ${userToken}` };
      const { data } = await api.post(
        `/api/create-category?company=${companyId}`,
        body,
        {
          headers,
        }
      );
      navigate("/dashboard/boilerplate-category");
      toast.success("Category created successfully");
      return data;
    } catch (error) {
      // permission error
      if (error?.response?.data?.detail) {
        return rejectWithValue("Permission issues");
      }

      // backend-validations error
      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }

      // axios error message
      if (error?.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong");
      }
    }
  }
);

// category detail api (data will be re-populate inside category form )
export const categoryDetailAPI = createAsyncThunk(
  "categories/categoryDetailAPI",
  async (payload, { rejectWithValue }) => {

    const { userToken, id, companyId } = payload;

    try {
      const headers = { Authorization: `Bearer ${userToken}` };
      const { data } = await api.get(
        `/api/category-detail?id=${id}&company=${companyId}`,
        {
          headers,
        }
      );
      return data;
    } catch (error) {
      // permission error
      if (error?.response?.data?.detail) {
        return rejectWithValue("Permission issues");
      }

      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong");
      }
    }
  }
);

// update category api
export const categoryUpdateAPI = createAsyncThunk(
  "categories/categoryUpdateAPI",
  async (payload, { rejectWithValue }) => {
    const { userToken, companyId, name, description, isActive, navigate, id } =
      payload;
    const body = {
      category_name: name,
      description: description,
      is_active: isActive === "Active" ? true : false,
    };

    try {
      const headers = { Authorization: `Bearer ${userToken}` };
      const { data } = await api.put(
        `/api/category-update/${id}?company=${companyId}`,
        body,
        {
          headers,
        }
      );
      navigate("/dashboard/boilerplate-category");
      toast.success("Category updated successfully");
      return data;
    } catch (error) {
      // permission error
      if (error?.response?.data?.detail) {
        return rejectWithValue("Permission issues");
      }

      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong..!!");
      }
    }
  }
);

// Thunk handlers

export const handlers = {
  categoryListAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.error = null;
      state.isSuccess = false;
      state.message = null;
      return state;
    },
    success: (state, action) => {
      const { data } = action?.payload;
      state.isLoading = false;
      state.categories = data;
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },

  categoryListingPaginatedAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.error = null;
      state.isSuccess = false;
      state.message = null;
      return state;
    },
    success: (state, action) => {
      const data = action?.payload;
      state.isLoading = false;
      state.previousPageUrl = data?.previous;
      state.nextPageUrl = data?.next;
      state.categories = data?.results?.data;
      state.totalCount = data?.count;
      state.error = null;
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },

  createCategoryAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.error = null;
      state.isSuccess = false;
      state.message = null;
      return state;
    },
    success: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = "Category created successfully";
      state.error = null;
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },

  categoryDetailAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.error = null;
      state.isSuccess = false;
      state.message = null;
      return state;
    },
    success: (state, action) => {
      const { data } = action?.payload;
      state.isLoading = false;
      state.categoryDetail = data[0];
      state.error = null;
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },

  categoryUpdateAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.error = null;
      state.isSuccess = false;
      state.message = null;
      return state;
    },
    success: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = "Category updated successfully";
      state.error = null;
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },
};
