import { createSlice } from '@reduxjs/toolkit'
import { googleLoginAPI, handlers } from './authThunk';

const initialState = {
  isLoading: false,
  error: null,
  isLoggedIn: false,
  userProfile: {
    name: null,
    email: null,
    picture: null,
    userCompany: null,
    userType: null,
  },
  isAdmin: false, 
  userToken: null,
};


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(googleLoginAPI.pending, handlers.googleLoginAPI.pending);
    builder.addCase(googleLoginAPI.fulfilled, handlers.googleLoginAPI.success);
    builder.addCase(googleLoginAPI.rejected, handlers.googleLoginAPI.rejected);
  }
})

export const getUserAuth = (state) => state.auth;
export const getUserCompanyId = (state) => state.auth.userProfile.userCompany;
export const userData = (state) => state.auth.userProfile;


export default authSlice.reducer;