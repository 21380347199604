import React, { useEffect } from "react";
import RoutesComponent from "./routes";
import { useDispatch, useSelector } from "react-redux";
import { incrementNotificationCount } from "./redux/slices/notification/notificationSlice";
import { getUserAuth } from "./redux/slices/auth/authSlice";
import { checkIsAdmin } from "./utils/checkIsAdmin";
import { useParams } from "react-router-dom";

const App = () => {
  const dispatch = useDispatch();
  const { isAdmin, userProfile } = useSelector(getUserAuth);
  const checkAdmin = checkIsAdmin(isAdmin, userProfile?.userType);

  const {search} = useParams()

  useEffect(() => {
    if (!checkAdmin) return;

    const notifySocket = new WebSocket('ws://ipboilerplate.server1.purelogics.net:8000/ws/notify/');
    notifySocket.onopen = function (event) {
      console.log('WebSocket is open now.');
    };
    notifySocket.onmessage = function (event) {
      console.log('WebSocket message received:', event);
      dispatch(incrementNotificationCount());
    };
    notifySocket.onclose = function (event) {
      console.log('WebSocket is closed now.');
    };
    notifySocket.onerror = function (error) {
      console.log('WebSocket error observed:', error);
    };
    return () => {
      notifySocket.close();
    };
  }, [checkAdmin, dispatch]);


  return (
    <RoutesComponent />
  )
}

export default App;