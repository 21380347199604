import React, { useEffect } from "react";

import { Box, Container } from "@mui/material";

import BrowserTabTitle from "../../../components/BrowserTabTitle";
import PageTitle from "../../../components/Dashboard/includes/pagetitle";
import BoilerPlateForm from "../../../components/Dashboard/boilerplateForm";
import AuditorModal from "./modals/AuditorModal";
import DeveloperModal from "./modals/DeveloperModal";
import { useDispatch, useSelector } from "react-redux";
import { getUserAuth, getUserCompanyId } from "../../../redux/slices/auth/authSlice";
import { categoryListAPI } from "../../../redux/slices/categories/categoriesThunk";
import { getAllCategories } from "../../../redux/slices/categories/categoriesSlice";
import AmplifySpinner from "../../../components/LoadingSpinner/AmplifySpinner";
import { techStackListingAPI } from "../../../redux/slices/techStacks/techStacksThunk";
import { getAllTechStacks } from "../../../redux/slices/techStacks/techStacksSlice";
import { getCodeAuditStatusAPI } from "../../../redux/slices/codeAuditStatus/codeAuditStatusThunk";
import { ErrorAlert } from "../../../components/Alerts/Alerts";
import { getAllBoilerplates, getBoilerplateDetail } from "../../../redux/slices/boilerplate/boilerplateSlice";
import BackdropLoading from "../../../components/BackdropLoading";
import { getAllCodeAuditStatus } from "../../../redux/slices/codeAuditStatus/codeAuditStatusSlice";
import { useParams } from "react-router-dom";
import { boilerplateDetailAPI } from "../../../redux/slices/boilerplate/boilerplateThunk";
import { setAuditors } from "../../../redux/slices/codeAuditors/codeAuditorsSlice";
import { setDevelopers } from "../../../redux/slices/developers/devlopersSlice";


const AddBoilerplate = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { userToken } = useSelector(getUserAuth);
  const company_id = useSelector(getUserCompanyId);
  const boilerDetail = useSelector(getBoilerplateDetail);
  const {
    isLoading: isBoilerplateLoading, error:
    boilerplateError
  } = useSelector(getAllBoilerplates);
  const {
    isLoading: isCategoryLoading,
    error: categoryError
  } = useSelector(getAllCategories);
  const {
    isLoading: isTechStackLoading,
    error: techStackError
  } = useSelector(getAllTechStacks);
  const {
    isLoading: isCodeAuditStatusLoading,
    error: codeAuditStatusError
  } = useSelector(getAllCodeAuditStatus);


  useEffect(() => {
    let payload = {};
    payload.userToken = userToken;
    payload.companyId = company_id;
    payload.id = id;

    dispatch(categoryListAPI(payload));
    dispatch(techStackListingAPI(payload));
    dispatch(getCodeAuditStatusAPI(payload));

    dispatch(setAuditors([]));
    dispatch(setDevelopers([]));

    id && dispatch(boilerplateDetailAPI(payload));

  }, [id, company_id, userToken, dispatch]);

  const isLoading = isCategoryLoading || isTechStackLoading || isCodeAuditStatusLoading;
  const error = categoryError || techStackError || codeAuditStatusError || boilerplateError;

  return (
    <>
      <BrowserTabTitle
        title={!id ?
          "Add Boilerplate" :
          "Update Boilerplate"
        }
      />
      <BackdropLoading isLoading={isBoilerplateLoading} />
      {error && <ErrorAlert message={error} />}
      {isLoading ? (
        <Container maxWidth="">
          <Box pt={2}>
            <AmplifySpinner />
          </Box>
        </Container>
      ) : (
        <>
          <Container maxWidth="">
            <PageTitle
              title={!id ?
                "Add Boilerplate" :
                `Update Boilerplate ${id}`}
              goBackPath={"/dashboard/boilerplate-listing/allstack"}
            />
            <BoilerPlateForm boilerDetail={id && boilerDetail} />
          </Container>
          <AuditorModal />
          <DeveloperModal />
        </>
      )}
    </>
  )
}

export default AddBoilerplate;