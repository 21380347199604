import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuditorModal: false,
  isDeveloperModal: false,
  isDRModal: false,
  isFilterModal: false,
};


export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openAuditorModal: (state) => {
      state.isAuditorModal = true;
    },
    closeAuditorModal: (state) => {
      state.isAuditorModal = false;
    },

    openDeveloperModal: (state) => {
      state.isDeveloperModal = true;
    },
    closeDeveloperModal: (state) => {
      state.isDeveloperModal = false;
    },

    openDRModal: (state) => {
      state.isDRModal = true;
    },
    closeDRModal: (state) => {
      state.isDRModal = false;
    },

    openFilterModal: (state) => {
      state.isFilterModal = true;
    },
    closeFilterModal: (state) => {
      state.isFilterModal = false;
    },
  },

})

export const {
  openAuditorModal,
  closeAuditorModal,
  openDeveloperModal,
  closeDeveloperModal,
  openDRModal,
  closeDRModal,
  openFilterModal,
  closeFilterModal,
} = modalSlice.actions;

export const isAuditorModalOpen = (state) => state.modal.isAuditorModal;
export const isDeveloperModalOpen = (state) => state.modal.isDeveloperModal;
export const isFilterModalOpen = (state) => state.modal.isFilterModal;
export const isDRModalOpen = (state) => state.modal.isDRModal;


export default modalSlice.reducer;