const styles = {
  chipListBox: (theme) => ({
    maxWidth: "300px",
  }),
  chipListBoiler: (theme) => ({
    margin: "0 5px 5px 0",
    padding: "5px, 0, 5px, 0",
    borderRadius: "unset",
    backgroundColor: "#7C7F90",
    color: "#ffff"
  }),
  deleteIcon: (theme) => ({
    color: "#ffffff !important",
    fontSize: "15px !important",
  })
};

export default styles;