import React, { useEffect } from 'react';
import BrowserTabTitle from '../../../components/BrowserTabTitle'
import { useParams } from 'react-router-dom';
import BackdropLoading from '../../../components/BackdropLoading';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories } from '../../../redux/slices/categories/categoriesSlice';
import { ErrorAlert } from '../../../components/Alerts/Alerts';
import { Container } from '@mui/material';
import PageTitle from '../../../components/Dashboard/includes/pagetitle/PageTitile';
import CategoryForm from '../../../components/Dashboard/category';
import { getUserAuth, getUserCompanyId } from '../../../redux/slices/auth/authSlice';
import { categoryDetailAPI } from '../../../redux/slices/categories/categoriesThunk';


const Category = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  
  
  const { userToken } = useSelector(getUserAuth);
  const company_id = useSelector(getUserCompanyId)

  const {
    isLoading,
    error,
    categoryDetail
  } = useSelector(getAllCategories);


  useEffect(() => {
    let payload = {};
    payload.userToken = userToken;
    payload.companyId = company_id;
    payload.id = id;
    id && dispatch(categoryDetailAPI(payload));
  }, [id, userToken, dispatch,company_id]);

  return (
    <>
      <BrowserTabTitle
        title={!id ?
          "Add Category" :
          "Update Category"
        }
      />
      <BackdropLoading isLoading={isLoading} />
      {error && <ErrorAlert message={error} />}
      <Container maxWidth="">
        <PageTitle
          title={!id ?
            "Add Category" :
            `Update Category ${id}`}
          goBackPath={"/dashboard/boilerplate-category"}
        />
        <CategoryForm ctgDetail={id && categoryDetail} />
      </Container>
    </>
  )
}

export default Category;