const styles = {
  techPaper: (theme) => ({
    maxWidth: "600px",
    border: "1px solid #E9EBEC",
    borderRadius: "4px",
    padding: "30px 30px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "350px"
    },
  }),
  techdf: (theme) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  }),
  techFI: (theme) => ({
    display: "flex",
    flexDirection: "row",
    gap: "90px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 0,
    },
  }),
  techTextField: (theme) => ({
    width: "380px",
    [theme.breakpoints.down("sm")]: {
      width: "280px"
    },
  }),
  techBtn: (theme) => ({
    padding: "10px 50px",
  }),
}

export default styles;