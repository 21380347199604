import { Box, Paper, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import styles from './styles';
import FeedbackForm from './FeedbackForm';
import { useSelector } from 'react-redux';
import { getAllFeedbacks } from '../../../redux/slices/feedbacks/feedbacksSlice';
import AmplifySpinner from '../../LoadingSpinner/AmplifySpinner';
import Feeds from './Feeds';

const Feedbacks = ({ feedData }) => {
  const { isLoading } = useSelector(getAllFeedbacks);

  // Memoize the sorted feed data
  const sortedFeedData = useMemo(() => 
    [...feedData].sort((a, b) => 
      new Date(b.created_at) - new Date(a.created_at)
    ), [feedData]
  );
   return (
    <Paper
      elevation={0}
      square={true}
      component="section"
      sx={styles.fdPaper}
    >
      {/* Feedback Form section */}
      <FeedbackForm />

      {/* Show all feedbacks section */}
      {isLoading ? (
        <Box sx={styles.fdSpinner}>
          <AmplifySpinner isLoading={isLoading} />
        </Box>
      ) : (
        <Box sx={styles.fdSection}>
          {sortedFeedData?.length > 0 ? (
            sortedFeedData.map((feed) => (
              <Feeds feed={feed} key={feed.id} />
            ))
          ) : (
            <Typography
              component="span"
              variant="body1"
              sx={styles.emptyText}
            >
              No feedback available yet
            </Typography>
          )}
        </Box>
      )}
    </Paper>
  );
};

export default Feedbacks;
