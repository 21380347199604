import React from "react";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import styles from "./styles";

// Destructured props for cleaner and easier-to-read code
const MultiSelect = ({
  style,          // Style for the Select component
  placeholder,    // Placeholder text
  id,             // ID for the component
  setSelectedData, // Callback function to set the selected data
  apiData = [],    // Default to an empty array if no data is provided
  register,       // Register for form handling (e.g. react-hook-form)
  error,          // Error state for form validation
  multiline,      // Boolean to toggle between single and multi-select
  selectedData = multiline ? [] : "", // Default selected data, array for multiline and string for single
}) => {

  // Handle change in the select input
  const handleChange = (event) => {
    const { target: { value } } = event;
    
    // Ensure value is always an array for multiline, handle single-select otherwise
    if (multiline) {
      setSelectedData(typeof value === "string" ? value.split(",") : value);
    } else {
      setSelectedData(value);
    }
  };

  // Optimized constants for menu styling
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, // Limit the dropdown height
        width: 250, // Set a fixed width
      },
    },
  };

  return (
    <FormControl error={Boolean(error)}>
      <Select
        label="Search boilerplate"
        id={id}
        multiple={multiline} // Toggle multiple select
        displayEmpty
        value={selectedData}
        {...register} // Spread register for easy form control binding
        onChange={handleChange}
        input={<OutlinedInput />}
        // Render the selected values based on whether it's a single or multi-select
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <Typography sx={styles.typPh}>{placeholder}</Typography>;
          }

          // For multiline, show selected values as chips, otherwise as text
          return multiline ? (
            <Box sx={styles.mltMenu}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          ) : (
            <Typography>{selected}</Typography>
          );
        }}
        MenuProps={MenuProps} // Apply menu properties
        sx={{ ...style }} // Apply custom styles passed as props
      >
        {apiData.length === 0 ? (
          <MenuItem disabled>
            <ListItemText primary="No data available" />
          </MenuItem>
        ) : (
          // Map through apiData and render each item in the dropdown
          apiData.map(({ id, name }) => (
            <MenuItem key={id} value={name}>
              {multiline && (
                <Checkbox
                  checked={selectedData.indexOf(name) > -1} // Check if the item is selected
                  sx={styles.chkBox}
                />
              )}
              <ListItemText primary={name} />
            </MenuItem>
          ))
        )}
      </Select>
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
};

export default MultiSelect;
