import React from "react";

import CloseIcon from '@mui/icons-material/Close';
import BoilerChip from "../boilerChip";
import { Box } from "@mui/material";

import styles from "./styles";

const ChipList = ({ items, handleDelete }) => {
  return (
    <Box sx={styles.chipListBox}>
      {items?.map((item, index) => (
        <BoilerChip
          key={index}
          index={index}
          item={item?.name}
          deleteIcon={<CloseIcon sx={styles.deleteIcon} />}
          handleDelete={handleDelete}
          styles={styles.chipListBoiler}
        />
      ))}
    </Box>
  )
}

export default ChipList;