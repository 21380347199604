const styles = {
  modal: (theme) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "508px",
    bgcolor: 'background.paper',
    border: '1px solid #E9EBEC',
    borderRadius: "4px",
    boxShadow: 24,
    padding: "15px 40px 30px",
    [theme.breakpoints.down("sm")]: {
      width: "380px",
    },
  }),

  modalCloseIcon: (theme) => ({
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "10px",
  }),

  modalFlexBox: (theme) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
  }),

  modalFieldBox: (theme) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  }),

  modalTextField: (theme) => ({
    width: "289px",
  }),

  modalBtn: (theme) => ({
    padding: "10px 50px",
  })
}

export default styles;