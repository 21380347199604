import React from "react";
import styles from "./styles";
import { Box, Button, Chip, Link, Paper, Stack, Typography } from "@mui/material";
import BoilerRating from "../boilerRating";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LaunchIcon from '@mui/icons-material/Launch';
import { useDispatch } from "react-redux";
import { openDRModal } from "../../../redux/slices/modal/ModalSlice";
import { setIsSuccess } from "../../../redux/slices/downloadReason/downloadReasonSlice";

const BoilerView = ({ boilerDetail }) => {
  const dispatch = useDispatch();

  const handleOpenDRModal = () => {
    dispatch(openDRModal());
    dispatch(setIsSuccess(false));
  };

  return (
    <Paper elevation={4} component="section" sx={styles.bvPaper}>
      <Stack direction="row" spacing={3} sx={styles.bvStack}>
        {/* Left side */}
        <Box flex={6} sx={styles.bvLeftSide}>
          {/* Boilerplate Name */}
          <Box sx={styles.bvpdf}>
            <Typography variant="h6" sx={styles.bvLabel}>
              Name:
            </Typography>
            <Typography variant="h6" sx={styles.bvContent}>
              {boilerDetail?.boilerplate_name}
            </Typography>
          </Box>

          {/* Boilerplate Description */}
          <Box sx={styles.bvpdf} pt={1}>
            <Typography variant="h6" sx={styles.bvLabel}>
              Description:
            </Typography>
            <Typography variant="body1" sx={styles.bvDescription}>
              {boilerDetail?.description}
            </Typography>
          </Box>

          {/* Boilerplate Category */}
          <Box sx={styles.bvpdf} pt={1}>
            <Typography variant="h6" sx={styles.bvLabel}>
              Category:
            </Typography>
            <Box sx={styles.bvTags}>
              {boilerDetail?.category?.map((value, index) => (
                <Chip
                  label={value}
                  size="small"
                  key={index}
                  sx={styles.bvChip}
                />
              ))}
            </Box>
          </Box>

          {/* Code Audit Report */}
          {boilerDetail?.code_audit_report && (
            <Box sx={styles.bvpdf} pt={1}>
              <Typography variant="h6" sx={styles.bvLabel}>
                Code Audit Report:
              </Typography>
              <Link
                href={boilerDetail?.code_audit_report}
                target="_blank"
                rel="noopener"
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <LaunchIcon sx={{ fontSize: "20px", color: "primary.main", mr: 1 }} />
                View Report
              </Link>
            </Box>
          )}

          {/* Code Audited by */}
          <Box sx={styles.bvpdf} pt={1}>
            <Typography variant="h6" sx={styles.bvLabel}>
              Code Audited by:
            </Typography>
            <Box sx={styles.bvTags}>
              {boilerDetail?.auditors?.length > 0 ? (
                boilerDetail.auditors.map((value, index) => (
                  <Chip
                    label={value?.name}
                    size="small"
                    key={index}
                    sx={styles.bvChip}
                  />
                ))
              ) : (
                <Typography variant="body1" sx={styles.bvContent}>
                  N/A
                </Typography>
              )}
            </Box>
          </Box>

          {/* Sample Work URL */}
          <Box sx={styles.bvpdf} pt={1}>
            <Typography variant="h6" sx={styles.bvLabel}>
              Sample Work URL:
            </Typography>
            <Link
              href={boilerDetail?.sample_work_url}
              target="_blank"
              rel="noopener"
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <LaunchIcon sx={{ fontSize: "20px", color: "primary.main", mr: 1 }} />
              View Sample
            </Link>
          </Box>
        </Box>

        {/* Right side */}
        <Box flex={6} sx={styles.bvRightSide}>
          {/* Tech stack(s) */}
          <Box sx={styles.bvpdf}>
            <Typography variant="h6" sx={styles.bvLabel}>
              Tech stack(s):
            </Typography>
            <Box sx={styles.bvTags}>
              {boilerDetail?.tech?.map((value, index) => (
                <Chip
                  label={value}
                  size="small"
                  sx={styles.bvChip}
                  key={index}
                />
              ))}
            </Box>
          </Box>

          {/* List of Keywords */}
          <Box sx={styles.bvpdf} pt={1}>
            <Typography variant="h6" sx={styles.bvLabel}>
              List of Keywords:
            </Typography>
            <Box sx={styles.bvTags}>
              {boilerDetail?.keywords?.map((value, index) => (
                <Chip
                  label={value}
                  size="small"
                  key={index}
                  sx={styles.bvChip}
                />
              ))}
            </Box>
          </Box>

          {/* Developers */}
          <Box sx={styles.bvpdf} pt={1}>
            <Typography variant="h6" sx={styles.bvLabel}>
              Developer(s):
            </Typography>
            <Box sx={styles.bvTags}>
              {boilerDetail?.developers?.length > 0 ? (
                boilerDetail?.developers?.map((value, index) => (
                  <Chip
                    label={value?.name}
                    size="small"
                    key={index}
                    sx={styles.bvChip}
                  />
                ))
              ) : (
                <Typography variant="body1" sx={styles.bvContent}>
                  N/A
                </Typography>
              )}
            </Box>
          </Box>

          {/* Bitbucket URL */}
          {boilerDetail?.bitbucket_url && (
            <Box sx={styles.bvpdf} pt={1}>
              <Typography variant="h6" sx={styles.bvLabel}>
                Bitbucket URL:
              </Typography>
              <Link
                href={boilerDetail?.bitbucket_url}
                target="_blank"
                rel="noopener"
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <LaunchIcon sx={{ fontSize: "20px", color: "primary.main", mr: 1 }} />
                View Repository
              </Link>
            </Box>
          )}

          {/* Code Audit Status */}
          {boilerDetail?.code_audit_status && (
            <Box sx={styles.bvpdf} pt={1}>
              <Typography variant="h6" sx={styles.bvLabel}>
                Code Audit Status:
              </Typography>
              <Chip
                label={boilerDetail?.code_audit_status}
                size="small"
                sx={styles.bvStatusChip}
              />
            </Box>
          )}

          {/* Code Quality */}
          <Box sx={styles.bvpdf} pt={1}>
            <Typography variant="h6" sx={styles.bvLabel}>
              Code Quality:
            </Typography>
            <Box>
              <BoilerRating value={boilerDetail?.code_quality} />
            </Box>
          </Box>
        </Box>
      </Stack>

      {/* File URL */}
      <Box sx={styles.bvDownloadButton} pt={2}>
        <Button
          variant="contained"
          onClick={() => handleOpenDRModal()}
          sx={{ display: 'flex', alignItems: 'center',borderRadius:'10px',padding:'12px' }}
        >
          <FileDownloadIcon sx={{ mr: 1 }} /> Download Boilerplate
        </Button>
      </Box>
    </Paper>
  );
};

export default BoilerView;
