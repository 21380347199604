import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Login from "../pages/Login";
import PageNotFound from "../pages/PageNotFound";
import ProtectedRoutes from "./protectedRoutes";
import AddBoilerplate from "../pages/Dashboard/AddBoilerplate";
import BoilerplateListing from "../pages/Dashboard/BoilerplateListing";
import ViewBoilerplate from "../pages/Dashboard/ViewBoilerplate";
import CategoryListing from "../pages/Dashboard/Category";
import TechstackListing from "../pages/Dashboard/Techstack";
import Techstack from "../pages/Dashboard/Techstack/Techstack";
import DashboardLayout from "../layouts/DashboardLayout";
import Category from "../pages/Dashboard/Category/Category";
import AdminProtectedRoutes from "./adminProtectedRoutes";
import ComingSoon from "../components/Dashboard/ComingSoon";

const RoutesComponent = () => {
  return (
    <Routes>
      {/* Login Routes */}
      <Route path="/">
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>

      {/* Dashboard Routes */}
      <Route element={<ProtectedRoutes />}>
        <Route
          path="/dashboard/*"
          element={
            <DashboardLayout>
              <Routes>
                <Route path="/" element={<ComingSoon />} />
                <Route
                  path="/boilerplate-listing/:search"
                  element={<BoilerplateListing />}
                />
                <Route
                  path="/view-boilerplate/:id"
                  element={<ViewBoilerplate />}
                />

                <Route element={<AdminProtectedRoutes />}>
                  <Route path="/add-boilerplate" element={<AddBoilerplate />} />
                  <Route
                    path="/update-boilerplate/:id"
                    element={<AddBoilerplate />}
                  />

                  <Route
                    path="/boilerplate-category"
                    element={<CategoryListing />}
                  />
                  <Route
                    path="/boilerplate-category/add"
                    element={<Category />}
                  />
                  <Route
                    path="/boilerplate-category/update/:id"
                    element={<Category />}
                  />

                  <Route
                    path="/boilerplate-techstack"
                    element={<TechstackListing />}
                  />
                  <Route
                    path="/boilerplate-techstack/add"
                    element={<Techstack />}
                  />
                  <Route
                    path="/boilerplate-techstack/update/:id"
                    element={<Techstack />}
                  />
                </Route>
              </Routes>
            </DashboardLayout>
          }
        />
      </Route>
    </Routes>
  );
};

export default RoutesComponent;
