import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./styles";
import BoilerRating from "../boilerRating";

const Feeds = ({ feed }) => {
  return (
    <Stack
      key={feed?.id}
      flexDirection={"row"}
      gap={"20px"}
      alignItems={"center"}
      mt={4}
    >
      <Avatar src={""} alt={"user"} />
      <Box>
        <Box sx={styles.userFd}>
          <Typography sx={styles.userFdName}>
            {feed?.developer_name}
          </Typography>
          <BoilerRating value={feed?.rating} />
        </Box>
        <Box>
          <Typography sx={styles.userFdText}>
            {feed?.feedback}
          </Typography>
        </Box>
      </Box>
    </Stack>
  )
}

export default Feeds;