import React, { useEffect } from 'react'
import BrowserTabTitle from '../../../components/BrowserTabTitle'
import { Box, Button, Container, Paper } from '@mui/material'
import PageTitle from '../../../components/Dashboard/includes/pagetitle/PageTitile'
import styles from './styles';
import BoilerTable from '../../../components/Dashboard/table';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAuth, getUserCompanyId } from '../../../redux/slices/auth/authSlice';
import { updateIdToTechId } from '../../../utils/sliceFilter';
import AddIcon from '@mui/icons-material/Add';
import { ErrorAlert } from '../../../components/Alerts/Alerts';
import { useNavigate } from 'react-router-dom';
import { getAllTechStacks } from '../../../redux/slices/techStacks/techStacksSlice';
import { techStackListingPaginatedAPI } from '../../../redux/slices/techStacks/techStacksThunk';
import { getURLParams } from '../../../utils/getUrlParams';


const TechstackListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userToken } = useSelector(getUserAuth);
  const company_id = useSelector(getUserCompanyId);
  const { isLoading, error, techStacks,previousPageUrl,nextPageUrl,totalCount } = useSelector(getAllTechStacks);


  const columns = [
    { id: 'tech_name', label: 'Techstack', minWidth: 112.3 },
    { id: 'description', label: 'Description', minWidth: 200.3 },
    { id: 'icon_file', label: 'Icon', minWidth: 100.09, align: "center" },
    { id: 'is_active', label: 'Active', minWidth: 112.09, align: "center" },
    { id: 'tech_id', label: 'Actions', minWidth: 112.09, align: "center" },
  ];


  useEffect(() => {
    if (company_id) {
      dispatch(techStackListingPaginatedAPI({ userToken, companyId: company_id }));
    }
  }, [company_id, dispatch, userToken]);

  const handleBackendPagination = (link) => {
    if (!link) {
      alert("End of pagination");
      return;
    }
    const params = getURLParams(link);

    let payload = {
      ...params,
      userToken,
      companyId: company_id,
    };
    dispatch(techStackListingPaginatedAPI(payload));
  }

  const handleAddTechBtn = () => {
    navigate("/dashboard/boilerplate-techstack/add");
  };

  return (
    <>
      <BrowserTabTitle title={"Boilerplate Techstack"} />
      {error && <ErrorAlert message={error} />}
      <Container maxWidth="" sx={styles.container}>
  <PageTitle title={"Boilerplate Techstack"} />
  <Box sx={styles.techBtnBox}>
    <Button
      variant="contained"
      onClick={() => handleAddTechBtn()}
    >
      <AddIcon /> Techstack
    </Button>
  </Box>

  <Box sx={styles.content}>
    <Paper
      elevation={0}
      square={true}
      component="section"
      sx={styles.techPaper}
    >
      <Box sx={styles.tableContainer}>
        <BoilerTable
          columns={columns}
          rows={updateIdToTechId(techStacks)}
          isLoading={isLoading}
          previousPageUrl={previousPageUrl}
          nextPageUrl={nextPageUrl}
          handleBackendPagination={handleBackendPagination}
          totalCount={totalCount}
        />
      </Box>
    </Paper>
  </Box>
</Container>

    </>
  )
}

export default TechstackListing;
