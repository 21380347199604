const styles = {
  tableHeadCell: (theme) => ({
    backgroundColor: "#F3F6F9",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#878A99",
    weight: 600,
  }),

  tableContainer: {
    marginBottom: "20px", // Adjust this value as needed
  },
  tablePagination: {
    paddingBottom: "20px",
    marginTop:"-1rem"
  },
  
  tableBodyCell: (theme) => ({
    backgroundColor: "#FFFFFF",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#212529",
    weight: 400,
    borderBottom: "none",
  }),
  tableEmptyText: (theme) => ({
    fontSize: "14px",
    fontStyle: "italic",
    height: "25vh",
    color: "#878A99",
  }),
  tableNA: (theme) => ({
    color: "#878A99",
  }),

  userProfile: (theme) => ({
    display: "flex",
    alignItems: "center",
    mr: 1,
  }),
  userAvatar: (theme) => ({
    width: 32,
    height: 32,
  }),
  username: (theme) => ({
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
  }),
};

export default styles;