const styles = {
  sbBox: (theme) => ({
    width: "249px",
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: "249px",
      backgroundColor: "#F6F8FA",
      borderRight: "1px solid #E9EBEC"
    },
    [theme.breakpoints.down(1268)]: {
      display: "none"
    },
  }),
  sbBoxBd: (theme) => ({
    width: "249px",
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: "249px",
      backgroundColor: "#F6F8FA",
      borderRight: "1px solid #E9EBEC"
    },
  }),
  sbLogo: (theme) => ({
    paddingTop: "24px",
    paddingLeft: "24px",
  }),
  sbTabs: (theme) => ({
    paddingTop: "20px",
    paddingLeft: "0 !important",

  }),
  sbTabsList: (theme) => ({
    paddingLeft: "24px",
  }),
  sbTabsListIcon: (theme) => ({
    minWidth: "33px !important",
  }),
  sbTabsSubIcon: (theme) => ({
    width: "33px !important",
  }),
  sbMenuText: (theme) => ({
    '& .MuiListItemText-primary': {
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "22.5px",
      color: `${theme.palette.secondary.main}`
    }
  }),
  sbSubMenuIcon: (theme) => ({
    '& .MuiListItemIcon-primary': {
      minWidth: "20px"
    }
  }),
  sbSubMenuText: (theme) => ({
    '& .MuiListItemText-primary': {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "21px",
      color: "#7C7F90",
    }
  }),
  sbSelectSubMenuText: (theme) => ({
    '& .MuiListItemText-primary': {
      fontWeight: 500,
      fontSize: "15px",
      lineHeight: "21px",
      color: "#ffffff",
    }
  })
}

export default styles;
