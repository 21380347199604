import React, { useEffect, useState } from "react";
import { Box, List, ListItemText, ListItemIcon, Collapse, Drawer, ListItemButton } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import styles from "./styles";
import colorLogo from "../../../../assets/login/logo_colored.svg";
import { useDispatch, useSelector } from "react-redux";
import { techStackFullListAPI, techStackListingAPI } from "../../../../redux/slices/techStacks/techStacksThunk";
import { getUserAuth, getUserCompanyId } from "../../../../redux/slices/auth/authSlice";
import { getAllTechStacks, selectedTechstack } from "../../../../redux/slices/techStacks/techStacksSlice";
import { useNavigate } from "react-router-dom";
import { checkIsAdmin } from "../../../../utils/checkIsAdmin";
import GridViewIcon from '@mui/icons-material/GridView';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import CodeSharpIcon from '@mui/icons-material/CodeSharp';
import CategoryIcon from '@mui/icons-material/Category';


const Sidebar = ({ drawOpen, toggleDrawer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openTabs, setOpenTabs] = useState({});
  const [selectedSubMenu, setSelectedSubMenu] = useState("");

  const { userToken } = useSelector(getUserAuth);
  const company_id = useSelector(getUserCompanyId);
  const { techStacks } = useSelector(getAllTechStacks);
  const { fullTechStacks } = useSelector(getAllTechStacks);
  const { isAdmin, userProfile } = useSelector(getUserAuth);
  const checkAdmin = checkIsAdmin(isAdmin, userProfile?.userType);
console.log("sidebar tech stacks",fullTechStacks)
  
useEffect(() => {
  if (company_id) {
    dispatch(techStackFullListAPI({ userToken, companyId: company_id })).then((result) => {
    });
  }
}, [dispatch, company_id, userToken]);

  // TechStackData
  const techStackData = [
    {
      name: "All Stack",
      param: "allstack",
      path: "/dashboard/boilerplate-listing"
    },
    
    ...fullTechStacks?.map((stack) => ({
      name: stack?.tech_name,
      param: stack?.tech_name,
      icon: <img src={stack?.icon_file} alt="" />,
      path: "/dashboard/boilerplate-listing"
    }))
  ];



  const tabs = [
    {
      name: "Dashboard",
      icon: <HomeSharpIcon />,
      isAdmin: true
    },
    {
      name: "TechStacks",
      icon: <GridViewIcon />,
      subMenus: techStackData,
      isAdmin: false
    },
    {
      name: "Category",
      icon: <CategoryIcon />,
      isAdmin: true
    },
    {
      name: "Techstack",
      icon: <CodeSharpIcon />,
      isAdmin: true
    },
  ];



  const handleTabClick = (tabName) => {
    setOpenTabs((prevOpenTabs) => ({
      ...prevOpenTabs,
      [tabName]: !prevOpenTabs[tabName],
    }));


    if (tabName === "TechStacks" && techStacks?.length === 0) {
      let payload = {};
      payload.userToken = userToken;
      payload.company_id = company_id;
      dispatch(techStackListingAPI(payload));
    }

    if (tabName === "Dashboard") {
      navigate("/dashboard");
    }

    if (tabName === "Feedbacks") {
      navigate("/dashboard/boilerplate-feedbacks");
    }

    if (tabName === "Category") {
      navigate("/dashboard/boilerplate-category");
    }

    if (tabName === "Techstack") {
      navigate("/dashboard/boilerplate-techstack");
    }
  };


  const handleSubMenuClick = (path, search) => {
    setSelectedSubMenu(search);
    dispatch(selectedTechstack(search))
    navigate(`${path}/${search}`);
  };

  // Filter tabs based on user type:
  // - Admin users see all tabs
  // - Non-admin users (Engineers) see only Techtsack tab
  const filteredTabs = tabs.filter(tab => checkAdmin || !tab.isAdmin);

  return (
    <Drawer
      variant={drawOpen ? "temporary" : "permanent"}
      open={drawOpen}
      onClose={toggleDrawer(false)}
      sx={drawOpen ? styles.sbBoxBd : styles.sbBox}
    >
      <Box
        sx={styles.sbLogo}
      >
        <img
          src={colorLogo}
          alt="purelogics"
          width={161}
          height={39.54}
        />
      </Box>
      <List
        sx={styles.sbTabs}
      >
        {filteredTabs.map((tab, index) => (
          <Box
            key={index}
          >
            <ListItemButton
              sx={styles.sbTabsList}
              onClick={() => handleTabClick(tab.name)}
            >
              <ListItemIcon
                sx={styles.sbTabsListIcon}
              >
                {tab.icon}
              </ListItemIcon>
              <ListItemText
                primary={tab.name}
                sx={styles.sbMenuText}
              />
              {/* Only show Expand/Collapse icon if there are submenus */}
              {tab.subMenus && (
                openTabs[tab.name] ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                ))}
            </ListItemButton>

            {/* Only render Collapse if there are submenus */}
            {tab.subMenus && (
              <Collapse
                in={openTabs[tab.name]}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                >
                  {tab.subMenus.map((subMenu, subIndex) => (
                    <ListItemButton
                      sx={{
                        pl: 5,
                        bgcolor: selectedSubMenu === subMenu.param ? "primary.light" : "transparent",
                        borderRadius: "4px",
                        margin: "5px 10px",
                        '&:hover': {
                          bgcolor: selectedSubMenu === subMenu.param && "primary.light",
                        }
                      }}
                      key={subIndex}
                      onClick={() => handleSubMenuClick(subMenu.path, subMenu.param)}
                    >
                      <ListItemIcon sx={{ minWidth: '30px' }}>
                        {subMenu?.icon ? (
                          <img src={subMenu?.icon?.props?.src} alt="" width={20} />
                        ) : (
                          <ListItemIcon
                            sx={styles.sbTabsListIcon}
                          >
                            <CodeSharpIcon />
                          </ListItemIcon>
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={subMenu.name}
                        sx={selectedSubMenu === subMenu.param ?
                          styles.sbSelectSubMenuText :
                          styles.sbSubMenuText
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </Box>
        ))}
      </List>
    </Drawer >
  );
};

export default Sidebar;
