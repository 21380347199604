import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/axiosInstance";
import { toast } from "react-toastify";

// Thunks



// Thunk for fetching sidebar tech stacks
export const fetchSidebarTechStacks = createAsyncThunk(
  'sidebarTechStacks/fetchSidebarTechStacks',
  async ({ userToken, companyId }, { rejectWithValue }) => {
    try {
      const headers = { Authorization: `Bearer ${userToken}` };
      const { data } = await api.get(`/api/techstack-listing?company=${companyId}`, { headers });
      console.log("fetchSidebarTechStacks", data);
      return data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }
      if (error?.message) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("Something went wrong");
    }
  }
);

export const techStackFullListAPI = createAsyncThunk(
  "techStacks/fullList",
  async ({ userToken, companyId }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/api/techstack-listing?company=${companyId}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return rejectWithValue("Tech stack not found for the given company.");
      }
      return rejectWithValue(
        error.response ? error.response.data : "Network error"
      );
    }
  }
);
// techstack listing API to get all techstack
export const techStackListingAPI = createAsyncThunk(
  "techStack/techStackListingAPI",
  async (payload, { rejectWithValue }) => {
    const { userToken, companyId } = payload;
    try {
      const headers = { Authorization: `Bearer ${userToken}` };
      const { data } = await api.get(
        `/api/techstack-listing?company=${companyId}`,
        { headers }
      );
      console.log("techstack listing", data);
      return data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong");
      }
    }
  }
);

// Thunk to get all Paginated tech stacks
export const techStackListingPaginatedAPI = createAsyncThunk(
  "techstack/techStackListingPaginatedAPI",
  async (payload, { rejectWithValue }) => {
    const { userToken, companyId, page: pageParam } = payload;

    let page = pageParam ?? "";
    try {
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };

      const response = await api.get(
        `/api/techstack-listing-paginated?page=${page}&company=${companyId}`,
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching tech stack listing:", error);

      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.message) {
        return rejectWithValue(error.message);
      }

      return rejectWithValue("Something went wrong");
    }
  }
);

// create techstack api for creating techstack
export const techStackCreateAPI = createAsyncThunk(
  "techStack/techStackCreateAPI",
  async (payload, { rejectWithValue }) => {
    const { userToken, name, description, icon, navigate, companyId } = payload;

    try {
      const headers = {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "multipart/form-data",
      };

      const formData = new FormData();
      formData.append("tech_name", name);
      formData.append("description", description);
      formData.append("icon_file", icon);

      const { data } = await api.post(
        `/api/techstack-create?company=${companyId}`,
        formData,
        {
          headers,
        }
      );
      navigate("/dashboard/boilerplate-techstack");
      toast.success("Techstack created successfully");
      return data;
    } catch (error) {
      // permission error
      if (error?.response?.data?.detail) {
        return rejectWithValue("Permission issues");
      }

      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong");
      }
    }
  }
);

// techstack detail api (data will be re-populate inside techstack form )
export const techStackDetailAPI = createAsyncThunk(
  "techStack/techStackDetailAPI",
  async (payload, { rejectWithValue }) => {
    const { userToken, id, companyId } = payload;
    try {
      const headers = { Authorization: `Bearer ${userToken}` };
      const { data } = await api.get(
        `/api/techstack-detail?id=${id}&company=${companyId}`,
        {
          headers,
        }
      );
      console.log("Tech stack Detail", data);
      return data;
    } catch (error) {
      // permission error
      if (error?.response?.data?.detail) {
        return rejectWithValue("Permission issues");
      }

      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong");
      }
    }
  }
);

// update techstack api
export const techStackUpdateAPI = createAsyncThunk(
  "techStack/techStackUpdateAPI",
  async (payload, { rejectWithValue }) => {
    const {
      userToken,
      id,
      name,
      description,
      icon,
      isActive,
      navigate,
      companyId,
    } = payload;

    let isIconFile = icon === undefined || icon === null || icon === "";

    try {
      const headers = {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "multipart/form-data",
      };

      const formData = new FormData();
      formData.append("tech_name", name);
      formData.append("description", description);
      !isIconFile && formData.append("icon_file", icon);
      formData.append("is_active", isActive === "Active" ? true : false);

      const { data } = await api.put(
        `/api/techstack-update/${id}?company=${companyId}`,
        formData,
        {
          headers,
        }
      );
      navigate("/dashboard/boilerplate-techstack");
      toast.success("Techstack updated successfully");
      return data;
    } catch (error) {
      // permission error
      if (error?.response?.data?.detail) {
        return rejectWithValue("Permission issues");
      }

      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong");
      }
    }
  }
);

// Thunk handlers

export const handlers = {
  techStackListingAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.error = null;
      state.isSuccess = false;
      state.message = null;
      return state;
    },
    success: (state, action) => {
      const { data } = action?.payload;
      state.isLoading = false;
      state.techStacks = data;
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },

  techStackListingPaginatedAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.error = null;
      state.isSuccess = false;
      state.message = null;
      return state;
    },
    success: (state, action) => {
      const data = action?.payload;
      state.isLoading = false;
      state.previousPageUrl = data?.previous;
      state.nextPageUrl = data?.next;
      state.techStacks = data?.results?.data;
      state.totalCount = data?.count;
      state.error = null;
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },

  techStackCreateAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.error = null;
      state.isSuccess = false;
      state.message = null;
      return state;
    },
    success: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = "Techstack created successfully";
      state.error = null;
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },

  techStackDetailAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.error = null;
      state.isSuccess = false;
      state.message = null;
      return state;
    },
    success: (state, action) => {
      const { data } = action?.payload;
      state.isLoading = false;
      state.techStackDetail = data[0];
      state.error = null;
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },

  techStackUpdateAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.error = null;
      state.isSuccess = false;
      state.message = null;
      return state;
    },
    success: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = "Techstack updated successfully";
      state.error = null;
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },
};
