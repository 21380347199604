import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/axiosInstance";
import { filterGetBoilerData } from "../../../utils/filterGetBoilerData";
import { setAuditors } from "../codeAuditors/codeAuditorsSlice";
import { setDevelopers } from "../developers/devlopersSlice";
import { toast } from "react-toastify";

// Thunks

// create boilerplate api
export const createBoilerplateAPI = createAsyncThunk(
  "boilerplate/createBoilerplateAPI",
  async (payload, { rejectWithValue }) => {
    const { navigate } = payload;
    try {
      const headers = {
        Authorization: `Bearer ${payload.userToken}`,
        "Content-Type": "multipart/form-data",
      };

      const formData = new FormData();
      formData.append("company", payload.companyId);
      formData.append("boilerplate_name", payload.name);
      formData.append("description", payload.description);
      formData.append("bitbucket_url", payload.bitbucketUrl);
      formData.append("sample_work_url", payload.sampleWorkUrl);
      formData.append("code_audit_report", payload.codeAuditReportLink);
      formData.append("code_audit_status", payload.codeAuditStatus);
      formData.append("code_quality", payload.rating);
      formData.append("comments", payload.comments);
      // formData.append("code_zip_file", payload.file, payload.file.name, {
      //   encoding: "binary",
      // });
      formData.append("category", JSON.stringify(payload.categories));
      formData.append("tech", JSON.stringify(payload.techStacks));
      formData.append("keywords", JSON.stringify(payload.keywords));
      formData.append("developers", JSON.stringify(payload.developers));
      formData.append("auditors", JSON.stringify(payload.auditors));

      // Ensure ZIP file is uploaded properly across both Ubuntu and Windows
      if (payload.file) {
        if (payload.file instanceof File) {
          // Append the file with its name and ensure binary encoding
          formData.append("code_zip_file", payload.file, payload.file.name);
        } else {
          return rejectWithValue("Invalid file format");
        }
      }
      const { data } = await api.post(`/api/create-boilerplate`, formData, {
        headers,
      });
      navigate("/dashboard/boilerplate-listing/allstack");
      toast.success("Boilerplate created successfully");
      return data;
    } catch (error) {
      let err = error?.response?.data;
      // permission error
      if (err?.detail) {
        return rejectWithValue("Permission issues");
      }

      if (Array.isArray(err) && err[0]?.email?.[0]) {
        return rejectWithValue(err[0]?.email[0]);
      }

      if (error?.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong");
      }
    }
  }
);

// boilerplate listing api to get all boilerplates
export const boilerplateListingAPI = createAsyncThunk(
  "boilerplate/boilerplateListingAPI",
  async (payload, { rejectWithValue }) => {
    const {
      userToken,
      search: searchParam,
      page: pageParam,
      name: boilerParam,
      category: ctgParam,
      techStack: techParam,
      companyId,
    } = payload;

    let page = pageParam ?? "";
    let ctg = ctgParam ?? "";
    let techStack = techParam ?? "";
    let boilerName = boilerParam ?? "";
    let search = searchParam ?? "";
    console.log("boilerplateListingAPI payload", payload);
    try {
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };
      const { data } = await api.get(
        `/api/boilerplate-listing?page=${page}&company=${companyId}&search=${
         search
        }&boilerplate_name=${boilerName}&category=${ctg}`,
        { headers }
      );
      return data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong");
      }
    }
  }
);

// get boilerplate detail api (date will be re-populate inside boilerplate form )
export const boilerplateDetailAPI = createAsyncThunk(
  "boilerplate/boilerplateDetailAPI",
  async (payload, { dispatch, rejectWithValue }) => {
    const { userToken, id, companyId } = payload;
    try {
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };
      const { data } = await api.get(
        `/api/boilerplate-detail/${id}?company=${companyId}`,
        { headers }
      );
      const filterData = filterGetBoilerData(data?.data[0]);
      dispatch(setAuditors(filterData.auditors));
      dispatch(setDevelopers(filterData.developers));
      return filterData;
    } catch (error) {
      // permission error
      if (error?.response?.data?.detail) {
        return rejectWithValue("Permission issues");
      }

      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong");
      }
    }
  }
);

// update boilerplate api
export const updateBoilerplateAPI = createAsyncThunk(
  "boilerplate/updateBoilerplateAPI",
  async (payload, { rejectWithValue }) => {
    const { navigate, companyId } = payload;
    const payloadFile = payload.file;

    let isNotBoilerFile =
      payloadFile === undefined || payloadFile === null || payloadFile === "";

    try {
      const headers = {
        Authorization: `Bearer ${payload.userToken}`,
        "Content-Type": "multipart/form-data",
      };
      const formData = new FormData();
      formData.append("company", payload.companyId);
      formData.append("boilerplate_name", payload.name);
      formData.append("description", payload.description);
      formData.append("bitbucket_url", payload.bitbucketUrl);
      formData.append("sample_work_url", payload.sampleWorkUrl);
      formData.append("code_audit_report", payload.codeAuditReportLink);
      formData.append("code_audit_status", payload.codeAuditStatus);
      formData.append("code_quality", payload.rating);
      formData.append("comments", payload.comments);
      isNotBoilerFile
        ? formData.append("code_zip_file", "")
        : formData.append("code_zip_file", payload.file);
      formData.append("category", JSON.stringify(payload.categories));
      formData.append("tech", JSON.stringify(payload.techStacks));
      formData.append("keywords", JSON.stringify(payload.keywords));
      formData.append("developers", JSON.stringify(payload.developers));
      formData.append("auditors", JSON.stringify(payload.auditors));
      formData.append(
        "is_active",
        JSON.stringify(payload.isActive === "Active" ? true : false)
      );

      const { data } = await api.put(
        `api/boilerplate-update/${payload.id}?company=${companyId}`,
        formData,
        { headers }
      );
      navigate("/dashboard/boilerplate-listing/allstack");
      toast.success("Boilerplate updated successfully");
      return data;
    } catch (error) {
      console.log(error);
      let err = error?.response?.data;
      // permission error
      if (err?.detail) {
        return rejectWithValue("Permission issues");
      }

      if (err[0]?.email[0]) {
        return rejectWithValue(err[0]?.email[0]);
      }

      if (error?.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong");
      }
    }
  }
);

// Thunk handlers

export const handlers = {
  // create boilerplate api
  createBoilerplateAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.message = null;
      state.isSuccess = false;
      state.error = null;
      return state;
    },
    success: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = "Boilerplate created successfully";
      state.error = null;
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },

  // boilerplate listing api
  boilerplateListingAPI: {
    pending: (state) => {
      state.isLoading = true;
      return state;
    },
    success: (state, action) => {
      const data = action?.payload;
      state.isLoading = false;
      state.previousPageUrl = data?.previous;
      state.nextPageUrl = data?.next;
      state.boilerplates = data?.results?.data;
      state.totalCount = data?.count;
      state.error = null;
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },

  // get boilerplate detail api
  boilerplateDetailAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.message = null;
      state.isSuccess = false;
      state.error = null;
      return state;
    },
    success: (state, action) => {
      const data = action?.payload;
      state.isLoading = false;
      state.boilerplateDetail = data;
      state.error = null;
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },

  // update boilerplate api
  updateBoilerplateAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error = null;
      return state;
    },
    success: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = "Boilerplate updated successfully";
      state.error = null;
      return state;
    },
    rejected: (state, action) => {
      console.log("error", action);
      state.isLoading = false;
      state.error =
        action?.payload === undefined
          ? "Something went wrong"
          : action?.payload;
      return state;
    },
  },
};
