import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/axiosInstance";

// Thunks 

export const getCodeAuditStatusAPI = createAsyncThunk(
  'codeAuditStatus/getCodeAuditStatusAPI',
  async (payload, { rejectWithValue }) => {
    const { userToken, companyId } = payload;
    try {
      const headers = { 'Authorization': `Bearer ${userToken}` };
      const { data } = await
        api.get
          (
            `/api/get-code-audit-status?company_id=${companyId}`,
            { headers }
          );
      return data;
    }
    catch (error) {
      let err = error?.response?.data;

      if (err?.detail) {
        return rejectWithValue("Permission issues");
      }

      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong");
      }
    }
  });


// Thunk handlers

export const handlers = {
  getCodeAuditStatusAPI: {
    pending: (state) => {
      state.isLoading = true
      return state;
    },
    success: (state, action) => {
      const { data } = action?.payload;
      state.isLoading = false;
      state.codeAuditStatus = data;
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload
      return state;
    }
  },
};