import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { z } from "zod";
import styles from "./styles";
import MultiSelect from "../multiSelect";
import { useDispatch, useSelector } from "react-redux";
import { getUserAuth, getUserCompanyId } from "../../../redux/slices/auth/authSlice";
import { categoryListAPI } from "../../../redux/slices/categories/categoriesThunk";
import { techStackListingAPI } from "../../../redux/slices/techStacks/techStacksThunk";
import { getAllCategories } from "../../../redux/slices/categories/categoriesSlice";
import { getAllTechStacks, getSelectedTechStack } from "../../../redux/slices/techStacks/techStacksSlice";
import { getCategoryIdAndName, getTechStackIdAndName } from "../../../utils/sliceFilter";
import { boilerplateListingAPI } from "../../../redux/slices/boilerplate/boilerplateThunk";
import ClearIcon from '@mui/icons-material/Clear';

const BoilerAdvancedFilters = () => {
  const dispatch = useDispatch();
  const { userToken } = useSelector(getUserAuth);
  const company_id = useSelector(getUserCompanyId);
  const selectedTech = useSelector(getSelectedTechStack);

  const [techStack, setTechStack] = useState([]);
  const [category, setCategory] = useState([]);

  const { categories: _categories } = useSelector(getAllCategories);
  const { techStacks: _techStacks } = useSelector(getAllTechStacks);

  const validationSchema = z.object({
    name: z.any(),
    techStack: z.any(),
    category: z.any(),
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: "",
      techStack: [],
      category: [],
    }
  });

 useEffect(() => {
    if (selectedTech) {
      reset({
        techStack: selectedTech === "allstack" ? [] : [selectedTech],
        category: [],
      });
    } else {
      reset({
        techStack: [],
        category: [],
      });
    }

  setTechStack(selectedTech === "allstack" ? [] : [selectedTech]);

  let payload = {};
  payload.userToken = userToken;
  payload.companyId = company_id;

  dispatch(categoryListAPI(payload));
  dispatch(techStackListingAPI(payload));
}, [company_id, userToken, dispatch, selectedTech, reset]);


 const handleFormReset = () => {
  reset({
    name: "",
    techStack: [],
    category: [],
  });
  setTechStack([]);
  setCategory([]);
};


const onSubmit = (data, e) => {
  e.preventDefault();

  // Convert techStack to array if it's not already
  const techStackArray = Array.isArray(data.techStack) ? data.techStack : [data.techStack];
  console.log("Tech Stack Array After Conversion:", techStackArray);

  const search = techStackArray.length === 0 || techStackArray.includes("All Stack") ? "allstack" : techStackArray.join(",");
  console.log("Search Parameter:", search);

  // Construct payload
  const payload = {
    ...data,
    userToken,
    companyId: company_id,
    search: search, 
    boilerplate_name: data.name,
    category: data.category.join(","),
  };

  console.log("Payload of Advance Boilerplate:", payload);

  // Dispatch API call
  dispatch(boilerplateListingAPI(payload));

  // Reset form
  reset({
    name: "",
    techStack: data.techStack,
    category: data.category,
  });
};

  return (
    <form
      autoComplete={"off"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack
        mb={2}
        direction="row"
        sx={styles.afStack}
      >
        <Box>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Search Boilerplate
          </Typography>
          <TextField
            placeholder="Search boilerplate"
            id="name"
            type="text"
            {...register("name")}
            helperText={errors.name?.message}
            error={Boolean(errors.name)}
            sx={styles.ctgTextField}
          />
        </Box>

        <Box>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Search by Category
          </Typography>
          <MultiSelect
            placeholder={"Search by category"}
            id={"isActive"}
            selectedData={category}
            setSelectedData={setCategory}
            apiData={getCategoryIdAndName(_categories)}
            register={register("category")}
            error={errors.category}
            multiline={false}
            style={{ width: "290px", background: "#ffffff" }}
          />
        </Box>

        <Box>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Search by Techstack
          </Typography>
          <MultiSelect
            placeholder={"Search by techstack"}
            id={"tech-stack"}
            selectedData={techStack}
            setSelectedData={setTechStack}
            apiData={getTechStackIdAndName(_techStacks)} 
            register={register("techStack")}
            error={errors.techStack}
            multiline={false}
            style={{ width: "280px", background: "#ffffff" }}
          />

        </Box>

        <Box sx={styles.closeBtn}>
          <Tooltip title="Clear filter">
            <IconButton
              aria-label="clear-filter"
              size="medium"
              onClick={() => handleFormReset()}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>

        <Box>
          <Button
            variant="contained"
            type={"submit"}
            sx={styles.ctgBtn}
          >
            Search
          </Button>
        </Box>
      </Stack>
    </form>
  )
}

export default BoilerAdvancedFilters;
