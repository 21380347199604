import React from "react";
import { Alert } from "@mui/material";
import styles from "./styles";

export const SuccessAlert = ({ message }) => {
  return (
    <Alert variant="filled" severity="success" sx={styles.alert}>
      {message}
    </Alert>
  )
};

export const ErrorAlert = ({ message }) => {
  return (
    <Alert variant="filled" severity="error" sx={styles.alert}>
      {message}
    </Alert>
  )
};

export const WarningAlert = ({ message }) => {
  return (
    <Alert variant="filled" severity="warning" sx={styles.alert}>
      {message}
    </Alert>
  )
};

export const InfoAlert = ({ message }) => {
  return (
    <Alert variant="filled" severity="info" sx={styles.alert}>
      {message}
    </Alert>
  )
};