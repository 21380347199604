import React from "react";

import { Box, Container } from "@mui/material";

import styles from "./styles";
import GoogleLogin from "./GoogleLogin";
import colorLogo from "../../assets/login/logo_colored.svg";
import AngularIcon from "../../assets/login/icons/angular.svg";
import ReactIcon from "../../assets/login/icons/react.svg";
import NextIcon from "../../assets/login/icons/next.svg";
import TailwindIcon from "../../assets/login/icons/tailwind.svg";
import HtmlIcon from "../../assets/login/icons/html.svg";
import CssIcon from "../../assets/login/icons/css.svg";


const MiddleSide = () => {
  return (
    <Box flex={4} className="d-flex">
      <Container maxWidth={"md"} sx={styles.midContainer}>
        <Box sx={styles.midBox}>
          <Box></Box>
          <Box>
            <img src={colorLogo} alt="purelogics" draggable="false" />
            <Box mt={6}>
              <GoogleLogin text={"Login with Company Account"} />
            </Box>
          </Box>
          <Box mb={5} sx={styles.techImgBox}>
            <Box sx={styles.techIcons}>
              <img src={AngularIcon} alt="Angular" draggable="false" />
              <img src={ReactIcon} alt="React" draggable="false" />
              <img src={NextIcon} alt="Next.js" draggable="false" />
              <img src={TailwindIcon} alt="Tailwind" draggable="false" />
              <img src={HtmlIcon} alt="HTML5" draggable="false" />
              <img src={CssIcon} alt="CSS3" draggable="false" />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default MiddleSide;
