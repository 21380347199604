import React from "react";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Modal,Typography } from "@mui/material";

import styles from "./styles";
import CloseIcon from '@mui/icons-material/Close';
import { addAuditor } from "../../../../../redux/slices/codeAuditors/codeAuditorsSlice";
import { closeFilterModal, isFilterModalOpen } from "../../../../../redux/slices/modal/ModalSlice";

const FilterModal = () => {
  const validationSchema = z.object({
    name: z.string().trim().min(1, { message: "Name is required" }),
    email: z.string().trim().email({ message: "Invalid email format" }).refine((email) => {
      const domain = email.split('@')[1];
      return domain === 'purelogics.com' || domain === 'purelogics.net';
    }, { message: "Please use an email address from either purelogics.com or purelogics.net." }),
  });

  const {
    reset,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: "",
      email: "",
    }
  });

  const dispatch = useDispatch();
  const isOpen = useSelector(isFilterModalOpen);

  const handleClose = () => {
    reset();
    dispatch(closeFilterModal());
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    dispatch(addAuditor(data));
    reset();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <Box sx={styles.modal}>
        {/* Close Button */}
        <Box sx={styles.modalCloseIcon}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Search filter Heading */}
        <Box>
          <Typography component="span" variant="h6">
            Search filters
          </Typography>
        </Box>

        {/* Actual Search filters */}
        <Box>
          
        </Box>
      </Box>
    </Modal>
  );
};

export default FilterModal;
