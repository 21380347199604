const styles = {
  abpPaper: (theme) => ({
    maxWidth: "967px",
    border: "1px solid #E9EBEC",
    borderRadius: "4px",
    padding: "30px 30px",
    [theme.breakpoints.down(1014)]: {
      display: "flex",
      justifyContent: "center",
    },
  }),
  abpStack: (theme) => ({
    [theme.breakpoints.down(1014)]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 0,
    },
  }),

  responsiveButton: (theme) => ({
    transition: "all 0.3s ease-in-out",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: theme.spacing(0.5, 1),
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      padding: theme.spacing(1, 2),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px",
      padding: theme.spacing(1.5, 3),
    },
  }),

  bottomStack: (theme) => ({
    [theme.breakpoints.down(1014)]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 0,
    },
  }),
  abpdf: (theme) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down(1014)]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  }),
  abpdfgCAB: (theme) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "35px",
    [theme.breakpoints.down(1014)]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "15px",
    },
  }),
  abpdfgDEVs: (theme) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "64px",
    [theme.breakpoints.down(1014)]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "15px",
    },
  }),
  abpRmk: (theme) => ({
    display: "flex",
    gap: 1.5,
    flexDirection: "row",
    alignItems: "center",
    pt: 2,
    [theme.breakpoints.down(1014)]: {
      flexDirection: "column",
      alignItems: "flex-start",
      pt: 1,
    },
  }),
  abpBtn: (theme) => ({
    padding: "10px 50px",
  }),
  abpTextField: (theme) => ({
    width: "289px",
  }),

  chipList: (theme) => ({
    display: "flex",
    flexDirection: "row",
    gap: "146px",
    pt: "15px",
    [theme.breakpoints.down(1014)]: {
      flexDirection: "column",
      padding: "10px 0 10px 0",
      gap: 0,
    },
  }),
};

export default styles;