import { createSlice } from '@reduxjs/toolkit'
import { getDownloadReasonAPI, handlers, postdownloadReasonAPI } from './downloadReasonThunk';

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: null,
  message: null,
  downloadReason: [],
};


export const downloadReasonSlice = createSlice({
  name: 'downloadReason',
  initialState,
  reducers: {
    setIsSuccess(state, action) {
      state.isSuccess = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDownloadReasonAPI.pending, handlers.getDownloadReasonAPI.pending);
    builder.addCase(getDownloadReasonAPI.fulfilled, handlers.getDownloadReasonAPI.success);
    builder.addCase(getDownloadReasonAPI.rejected, handlers.getDownloadReasonAPI.rejected);

    builder.addCase(postdownloadReasonAPI.pending, handlers.postdownloadReasonAPI.pending);
    builder.addCase(postdownloadReasonAPI.fulfilled, handlers.postdownloadReasonAPI.success);
    builder.addCase(postdownloadReasonAPI.rejected, handlers.postdownloadReasonAPI.rejected);
  }
})


export const { setIsSuccess } = downloadReasonSlice.actions;

export const getDownloadReasons = (state) => state.downloadReason;

export default downloadReasonSlice.reducer;