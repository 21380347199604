const styles = {
  fdPaper: (theme) => ({
    maxWidth: "1026px",
    border: "1px solid #E9EBEC",
    borderRadius: "4px",
    padding: "20px 30px",
    marginTop: "15px",
  }),
  fdStack: (theme) => ({
    alignItems: "center",
    [theme.breakpoints.down(1074)]: {
      flexDirection: "column",
      alignItems: "unset",
      gap: "10px",
    },
  }),
  fdTf: (theme) => ({
    width: "800px",
    paddingTop: "22px",
    [theme.breakpoints.down(1074)]: {
      paddingTop: "unset",
      width: "100%",
    },
  }),
  fdRating: (theme) => ({
    display: "flex",
    justifyContent: "flex-end"
  }),
  fdSpinner: (theme) => ({
    padding: "20px 0 0 5px"
  }),
  fdSection: (theme) => ({
    marginTop: "15px",
    maxHeight: "400px",
    overflowY: "auto",
  }),
  emptyText: (theme) => ({
    fontSize: "14px",
    fontStyle: "italic",
    color: "#878A99",
    paddingTop: "10px",
  }),
  userFd: (theme) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }),
  userFdName: (theme) => ({
    fontWeight: 700,
    fontSize: "15px",
    lineHeight: "22.5px",
    marginRight: "5px"
  }),
  userFdText: (theme) => ({
    fontWeight: 400,
    fontSize: "14px",
    color: "#212529",
  }),
}

export default styles;