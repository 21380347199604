const styles = {
  nbToolbar: (theme) => ({
    justifyContent: "space-between",
  }),

  responsiveButton: (theme) => ({
    transition: 'all 0.3s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      fontSize: "12px",
      padding: theme.spacing(0.5, 1),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: "14px",
      padding: theme.spacing(1, 2),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "16px",
      padding: theme.spacing(1.5, 3),
    },
  }),

  nbBox: (theme) => ({
    display: "flex",
    alignItems: "center",
  }),
  drawerOpener: (theme) => ({
    [theme.breakpoints.up(1268)]: {
      display: "none"
    },
  }),
  nbSb: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    ml: 1,
  }),

  nbfc: (theme) => ({
    display: "flex",
    alignItems: "center",
    gap: "10px"
  }),

  userProfile: (theme) => ({
    display: "flex",
    alignItems: "center",
    mr: 1,
  }),
  userText: (theme) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  }),
  username: (theme) => ({
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
  }),
  userType: (theme) => ({
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#878A99",
  }),
}


export default styles;
