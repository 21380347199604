const styles = {
  bvPaper: (theme) => ({
    maxWidth: "100%",
    width: "100%",
    border: "1px solid #E9EBEC",
    borderRadius: "4px",
    padding: "20px",
    backgroundColor: "#f5f5f5",
    [theme.breakpoints.up("sm")]: {
      padding: "30px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "40px",
      maxWidth: "1026px",
    },
  }),

//  bvDownloadButton: (theme) => ({
//     transition: "all 0.3s ease-in-out",
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//     borderRadius: "8px",
//     textTransform: "none", // Keeps text in the default case
//     boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)", // Slight shadow for depth
//     "&:hover": {
//       backgroundColor: theme.palette.primary.dark,
//       transform: "scale(1.05)",
//     },
//     [theme.breakpoints.down("xs")]: {
//       fontSize: "10px",
//       padding: theme.spacing(0.5, 0.75),
//     },
//     [theme.breakpoints.down("sm")]: {
//       fontSize: "12px",
//       padding: theme.spacing(0.75, 1.25),
//     },
//     [theme.breakpoints.up("md")]: {
//       fontSize: "14px",
//       padding: theme.spacing(1, 2),
//     },
//     [theme.breakpoints.up("lg")]: {
//       fontSize: "16px",
//       padding: theme.spacing(1.5, 3),
//     },
//   }),
  bvStack: (theme) => ({
    flexDirection: "column",
    gap: "20px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      gap: "30px",
    },
  }),
  bvpdf: (theme) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  }),
  bvBD: (theme) => ({
    maxWidth: "100%",
    maxHeight: "110px",
    overflowY: "auto",
    [theme.breakpoints.up("md")]: {
      maxWidth: "365px",
    },
  }),
  bvCB: (theme) => ({
    maxWidth: "365px",
  }),
  bvChip: (theme) => ({
    backgroundColor: "#e0e0e0",
    color: "#333",
    fontWeight: "medium",
    margin: "4px 0",
  }),
  bvLabel: (theme) => ({
    fontWeight: "bold",
    color: "#333",
    marginBottom: "8px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "0",
      marginRight: "8px",
    },
  }),
  bvContent: (theme) => ({
    color: "#555", // Medium gray for content text
    fontSize: "1rem",
  }),
  bvDescription: (theme) => ({
    lineHeight: 1.6,
    color: "#666",
    marginTop: "8px",
  }),
};

export default styles;