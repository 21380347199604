const styles = {
  afStack: (theme) => ({
    gap: "10px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      // gap: 0,
    },
  }),
  ctgdf: (theme) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  }),
  closeBtn: (theme) => ({
    display: "flex",
    marginTop:"25px",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  }),
  ctgTextField: (theme) => ({
    width: "290px",
    '& .MuiOutlinedInput-root': {
      background: "#ffffff"
    },
    [theme.breakpoints.down("sm")]: {
      width: "290px"
    },
  }),
  ctgMS: (theme) => ({
    width: "380px",
    [theme.breakpoints.down("sm")]: {
      width: "290px"
    },
  }),
  ctgBtn: (theme) => ({
    padding: "10px 50px",
    marginTop:"27px"
  }),
}

export default styles;