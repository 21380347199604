import React from 'react';

import { Box } from '@mui/material';

import styles from './styles';
import img1 from "../../assets/login/top_right_corner.svg";
import IconImages from "../../assets/login/download.png"

const RightSide = () => {
  return (
    <Box
      flex={3}
      sx={styles.rightBox}
    >
      <Box mt={4} mr={4}>
        <img src={img1} alt="upcorner" draggable="false"/>
      </Box>
      <Box mb={0} sx={styles.techImgBox}>
            <Box sx={styles.techIcons}>
              <img src={IconImages} alt="Angular" draggable="false" />
            </Box>
      </Box>
    </Box>
  )
}

export default RightSide;