import { createSlice } from '@reduxjs/toolkit'
import { handlers, listNotificationAPI, updateNotificationAPI } from './notificationThunk';

const initialState = {
  isLoading: false,
  isSuccess: false,
  message: null,
  error: null,
  notifications: [],
  notificationCount: 0,
};


export const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    incrementNotificationCount: (state) => {
      state.notificationCount += 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listNotificationAPI.pending, handlers.listNotificationAPI.pending);
    builder.addCase(listNotificationAPI.fulfilled, handlers.listNotificationAPI.success);
    builder.addCase(listNotificationAPI.rejected, handlers.listNotificationAPI.rejected);

    builder.addCase(updateNotificationAPI.pending, handlers.updateNotificationAPI.pending);
    builder.addCase(updateNotificationAPI.fulfilled, handlers.updateNotificationAPI.success);
    builder.addCase(updateNotificationAPI.rejected, handlers.updateNotificationAPI.rejected);
  }
})

export const getAllNotificaitons = (state) => state.notifications;
export const { incrementNotificationCount } = notifications.actions;

export default notifications.reducer;


