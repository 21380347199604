import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  developers: [],
};


export const developersSlice = createSlice({
  name: 'developers',
  initialState,
  reducers: {
    addDeveloper: (state, action) => {
      state.developers.push(action.payload);
    },
    setDevelopers: (state, action) => {
      state.developers = action.payload;
    },
    removeDeveloper: (state, action) => {
      state.developers = state.developers.filter((developer, index) => index !== action.payload);
    },
  },

})


export const { addDeveloper, setDevelopers, removeDeveloper } = developersSlice.actions;
export const getDevelopers = (state) => state.developers.developers;



export default developersSlice.reducer;