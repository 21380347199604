import React from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import auditorLogo from "../../../assets/dashboard/auditor-modal/auditor.svg";
import CloseIcon from '@mui/icons-material/Close';

const FormModal = (props) => {
  const {
    isOpen,
    handleClose,
    heading,
    title,
    description,
    children,
    isIcon,
    styles,
  } = props;


  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <Box sx={styles.modal}>
        <Box sx={
          heading ?
            styles.modalHead :
            styles.modalCloseIcon
        }>
          {heading && (
            <Typography
              variant="h2"
              component="h1"
              sx={styles.typTitle}
            >
              {heading}
            </Typography>
          )}
          <IconButton
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {isIcon && (
          <Box sx={styles.modalFlexBox}>
            <img src={auditorLogo} alt="auditor" />
          </Box>
        )}
        <Box sx={styles.modalFlexBox}>
          <Typography variant="h2" component="h1" sx={styles.typTitle}>
            {title}
          </Typography>
          <Typography variant="body2" component="p">
            {description}
          </Typography>
        </Box>
        <Box>
          {children}
        </Box>
      </Box>
    </Modal>
  );
};

export default FormModal;
