export const getSelectedIds = (selectedNames, items) => {
  return items
    ?.filter(item => selectedNames.includes(item.name))
    ?.map(item => item.id);
};

export const getSelectedId = (selectedName, items) => {
  const item = items?.find(item => item.name === selectedName);
  return item ? item.id : null;
};

// .filter(category => category.is_active)
export const getCategoryIdAndName = (categories) => {
  return categories
    ?.map(category => ({ id: category.id, name: category.category_name }));
};

export const getTechStackIdAndName = (techStacks) => {
  // Include "All Stack" at the beginning of the array
  return [
    { id: "allstack", name: "All Stack" },
    ...techStacks.map((techStack) => ({
      id: techStack.id,
      name: techStack.tech_name,
    })),
  ];
};


export const formatTechStackOptions = (techStacks = []) => {
  if (!Array.isArray(techStacks)) return [];
  // Without "All Stack" at the beginning of the array
  return techStacks.map((techStack) => ({
    id: techStack.id,
    name: techStack.tech_name,
  }));
};


export const getCodeAuditStatusIdAndName = (codeAuditStatus) => {
  return codeAuditStatus
    ?.map(codeAudit => ({ id: codeAudit.id, name: codeAudit.status }));
};

export const updateIdToCategoryId = (data) => {
  return data?.map(item => {
    const { id, ...rest } = item;
    return { category_id: id, ...rest };
  });
};

export const updateIdToTechId = (data) => {
  return data?.map(item => {
    const { id, ...rest } = item;
    return { tech_id: id, ...rest };
  });
}

export const renameAuditorFields = (data) => {
  return data.map(auditor => ({
    auditor_name: auditor.name,
    auditor_email: auditor.email
  }));
};

export const renameDeveloperFields = (data) => {
  return data.map(auditor => ({
    developer_name: auditor.name,
    developer_email: auditor.email
  }));
};